import React from "react";

import {
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Table,
  Button,
  Label,
  Input,
  FormGroup,
  Form,
  CardBody,
  Card,
  CardHeader,
  FormFeedback,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';

import Widget from "../../components/Widget/Widget";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import s from "./Bill.module.scss";
import { connect } from "react-redux";
import {
  createPackage,
  CREATE_PACKAGE_FAILURE,
  CREATE_PACKAGE_SUCCESS,
} from "../../actions/package";
import PropTypes from "prop-types";
import jwt from "jsonwebtoken";
import { DOC_TYPE, PACK_TYPE, CURRENCY, SHIPPING_SERVICES, SHIPPING_SERVICES_SPECIFIC,
  SHIPPING_SERVICES_5500, USER_ROLE } from "../../constants";
import { confirmAlert } from "react-confirm-alert"; // Import
import moment from 'moment';
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import { Country, City } from "country-state-city";
import validator from "validator";
import Rounding from "../../utils/rounding";
import axios from "axios";
import Select from "react-select";
import ImageUploading from "react-images-uploading";

let timerId;
const maxNumber = 12;
class Bill extends React.Component {
  initBillValidation = {
    errMsg: "",

    senderCompanyName: false,
    senderAddress: false,
    senderPhone: false,
    senderName: false,
    senderEmail: false,

    receiverCompanyName: false,
    receiverName: false,
    receiverPhone: false,
    receiverCountry: false,
    receiverPostalCode: false,
    receiverCity: false,
    receiverAddr1: false,
    receiverAddr2: false,
    packContent: false,

    docCount: false,
    docWeight: false,

    logisticService: false,
    packs: false,
    kangoUsers: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      termData: '',
      isOpenTerm: false,
      disableSubmitBtn: false,
      checkDk: false,
      validDkMess: "",
      currUser: {},
      currUser2: {},
      packList: [
        {
          quantity: "",
          type: "",
          length: "",
          width: "",
          height: "",
          weight: "",
          convertRatio: "",
          chargedWeight: "",
          images: [],
        },
      ],
      packInvoiceList: [
        {
          description: "",
          unit: "",
          quantity: "",
          unitPrice: "",
          subTotal: "",
        },
      ],
      billInfo: {
        companyUserID: "",
        senderCompanyName: "",
        senderName: "",
        senderContact: "",
        senderPhone: "",
        senderEmail: "",
        senderCountry: "",
        receiverCompanyName: "",
        receiverName: "",
        receiverPhone: "",
        receiverEmail: "",
        receiverCountry: "Australia - AU",
        receiverPostalCode: "",
        receiverCity: "",
        receiverProvince: "",
        receiverAddr1: "",
        receiverAddr2: "",
        receiverAddr3: "",
        logisticService: "",
        referenceCode: "",
        docCount: 0,
        docWeight: 0,
        totalPrice: 0,
        packContent: "",
        packInvoiceValue: "",
        packInvoiceUnit: "USD",
        packageType: DOC_TYPE,
        hawbCode: "",
        invoices: JSON.stringify([
          {
            description: "",
            unit: "",
            quantity: "",
            unitPrice: "",
            subTotal: "",
          },
        ]).replaceAll(`\"`, `\\"`),
        packs: JSON.stringify([
          {
            quantity: "",
            type: "",
            length: "",
            width: "",
            height: "",
            weight: "",
            convertRatio: "",
            chargedWeight: "",
          },
        ]).replaceAll(`\"`, `\\"`),
        invoiceExportFormat: "",
      },

      billValidation: this.initBillValidation,
      packsDetailsValidation: [
        {
          errMsg: "",
          quantity: false,
          length: false,
          width: false,
          height: false,
          weight: false,
        },
      ],
      tpValid: true,
      billupInfo: '',
      listServices: [],
      selectedCustomer: {
        name: '',
        khCode: '',
        phone: '',
        address: ''
      },
      selectedCustomerKH: {
        name: '',
        khCode: '',
        phone: '',
        address: ''
      },
      images: [],
      imagesEdit: [],
      createBillup: {
        group: 'Hàng thường',
        totalPrice: 0,
        bhPrice: 0,
        paymentType: 'Chuyển khoản',
        note: "",
        reportStatus: 'Chưa thanh toán',
      },
      search: '',
      listCustomer: [],
      listCustomerKH: [],
      selectedUser: ''
    };

    this.handlePackageTypeChange = this.handlePackageTypeChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onPackInfoChange = this.onPackInfoChange.bind(this);
    this.onRemoveRecord = this.onRemoveRecord.bind(this);
    this.onRemovePackInvoiceRecord = this.onRemovePackInvoiceRecord.bind(this);
    this.addPackage = this.addPackage.bind(this);
    this.addPackageInvoice = this.addPackageInvoice.bind(this);
    this.validateBillInfo = this.validateBillInfo.bind(this);
    this.onSelectedUser = this.onSelectedUser.bind(this);
    this.viewBillup = this.viewBillup.bind(this);
    this.getAllService = this.getAllService.bind(this);
    this.getListCustomer = this.getListCustomer.bind(this);
    this.getListCustomerKH = this.getListCustomerKH.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeKH = this.handleInputChangeKH.bind(this);
    this.onChange2 = this.onChange2.bind(this);
    this.setImages = this.setImages.bind(this);
    this.onCreateBillupChange = this.onCreateBillupChange.bind(this);
    this.handleFileSelect = this.handleFileSelect.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.onInfoChange = this.onInfoChange.bind(this);
  }

  /* eslint-disable */
  static propTypes = {
    package: PropTypes.any,
    isCreating: PropTypes.bool,
    errMsg: PropTypes.string,
    type: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
  };
  /* eslint-enable */

  static defaultProps = {
    package: {},
    isCreating: false,
    errMsg: "",
    type: "",
  };


  onInfoChange = (e) => {
    const { name, value } = e.target;
    var newInfo = this.state.billInfo;

    newInfo[name] = value;
    this.setState(newInfo);
    this.validateBillOnInfoChange(name, value)
    if (name === 'logisticService' && this.state.billInfo.packageType === PACK_TYPE) {
      let ratioConstant = 5000;
      const currentService = this.state.listServices.find((e) => e.name === value);
      if (currentService) {
        ratioConstant = currentService.convertNumber;
      }

      // let ratioConstant = this.state.currUser.role === USER_ROLE.CUSTOMER &&
      // value && SHIPPING_SERVICES_SPECIFIC.includes(value.toUpperCase()) ? 6000 : 5000
      //
      // ratioConstant = (this.state.currUser?.companyName.indexOf('TIN PHAT EXPRESS CO.,LTD') !== -1)
      // && value && SHIPPING_SERVICES_5500.includes(value.toUpperCase()) ? 5500 : ratioConstant
      //
      // ratioConstant = (this.state.currUser?.companyName.indexOf('KANGO') !== -1) ||
      // this.state.currUser.role === USER_ROLE.SALES ? 5000 : ratioConstant

      const list = [...this.state.packList]
      for (let index = 0; index < list.length; index++) {
        list[index]["convertRatio"] = Rounding((list[index]["length"] * list[index]["width"] * list[index]["height"]) / ratioConstant)
        list[index]['chargedWeight'] = list[index]['weight'] > list[index]['convertRatio']
          ? Rounding(parseFloat(list[index]['weight']).toFixed(1) * parseInt(list[index]['quantity']))
          : Rounding(list[index]['convertRatio'] * list[index]['quantity'])
      }
      this.setState((prevState) => ({
        packList: list,
        billInfo: {
          ...prevState.billInfo,
          packs: JSON.stringify(list).replaceAll(`\"`, `\\"`),
        },
      }))
    }
  };

  async getAllService(params = {}) {
    try {
      let url = `/get-all-service-name`
      const res = await axios.get(url)
      if (res && res.data) {
        // filter by role user if ops
        let listServices = res.data
        if (this.state.currUser.role === USER_ROLE.OPS) {
          listServices = res.data.filter(e => e.type === 'fwd')
        }
        this.setState({listServices: listServices})
        // save to local storage
        localStorage.setItem('listServices', JSON.stringify(res.data))
      }
    } catch (e) {
      console.error(e)
    }
  }

  onChange2 = (imageList, addUpdateIndex) => {
    // data for submit
    this.setImages(imageList);
  };

  setImages(imageList) {
    this.setState({images: imageList})
  }

  handleFileSelect = (e, i) => {
    // check each pack of list pack and save images
    const { name, files } = e.target;
    const list = [...this.state.packList];
    list[i][name] = files;
    this.setState({packList: list});
  }

  onImageEditRemove = (index) => {
    let { imagesEdit } = this.state
    const filteredItems = imagesEdit.filter(item => item !== imagesEdit[index])
    this.setState({imagesEdit: filteredItems})
  }

  onSelectChange = (value, action) => {
    var newInfo = this.state.billInfo;
    newInfo[action.name] = value.value;
    this.setState(newInfo)
    this.validateBillOnInfoChange(action.name, value)
  }
  onSelectedUser = (e) => {
    const value = e.target.value
    if (value) {
      const obj = this.state.listCustomer.find(e => e.id == value)
      this.setState({selectedUser: value});
    } else {
      this.setState({selectedUser: ''});
    }
  }
  async viewBillup(e) {
    e.preventDefault();
    const {kgCode} = this.state.billInfo
    if (kgCode) {
      const data = await this.getBillupById(kgCode)
      if (data) {
        this.setState({billupInfo: data})
        return
      }
    }
    this.setState({billupInfo: ''})
  }
  async getBillupById(id) {
    const { kgCode } = this.state.billInfo
    if (kgCode) {
      try {
        const { data } = await axios.post(`/get-billup/${kgCode}`)
        if (!data) {
          this.setState({tpValid: false})
          return
        }
        this.setState({tpValid: true})
        return data
      } catch (e) {
        return ''
      }
    }
    return ''
  }
  formatNumber (num) {
    if (num) {
      return new Intl.NumberFormat().format(num)
    }
    return num
  }

  onPackInfoChange = (e, index) => {
    // validate here
    const { name, value } = e.target;
    const list = [...this.state.packList];
    list[index][name] = value;

    // update convertRatio
    if (name === "length" || name === "width" || name === "height") {
      const { logisticService } = this.state.billInfo
      let ratioConstant = 5000;
      const currentService = this.state.listServices.find((e) => e.name === logisticService);
      if (currentService) {
        ratioConstant = currentService.convertNumber;
      }
      // let ratioConstant = this.state.currUser.role === USER_ROLE.CUSTOMER &&
      // logisticService && SHIPPING_SERVICES_SPECIFIC.includes(logisticService.toUpperCase()) ? 6000 : 5000
      //
      // ratioConstant = (this.state.currUser?.companyName.indexOf('TIN PHAT EXPRESS CO.,LTD') !== -1)
      // && logisticService && SHIPPING_SERVICES_5500.includes(logisticService.toUpperCase()) ? 5500 : ratioConstant
      //
      // ratioConstant = (this.state.currUser?.companyName.indexOf('KANGO') !== -1) ||
      // this.state.currUser.role === USER_ROLE.SALES ? 5000 : ratioConstant

      list[index]["convertRatio"] = Rounding(
        (list[index]["length"] * list[index]["width"] * list[index]["height"]) / ratioConstant
      );
    }
    // update charged weight
    list[index]["weight"] > list[index]["convertRatio"]
      ? (list[index]["chargedWeight"] = Rounding(
        parseFloat(list[index]["weight"]).toFixed(1) *
        parseInt(list[index]["quantity"])
      ))
      : (list[index]["chargedWeight"] = Rounding(
        list[index]["convertRatio"] * list[index]["quantity"]
      ));

    this.setState((prevState) => ({
      packList: list,
      billInfo: {
        ...prevState.billInfo,
        packs: JSON.stringify(list).replaceAll(`\"`, `\\"`),
      },
    }));
    this.validatePackOnPackInfoChange(name, value, index);
  };

  validatePacksDetails(packsDetails) {
    let validations = this.state.packsDetailsValidation;
    let isValid = true;

    packsDetails.forEach((pack, i) => {
      let errMsgs = [];

      if (pack.quantity <= 0) {
        isValid = false;

        validations[i].quantity = true;
        errMsgs.push("Quantity is required.");
      } else {
        validations[i].quantity = false;
      }

      if (pack.height <= 0) {
        isValid = false;

        validations[i].height = true;
        errMsgs.push("Height is required.");
      } else {
        validations[i].height = false;
      }

      if (pack.width <= 0) {
        isValid = false;

        validations[i].width = true;
        errMsgs.push("Width is required.");
      } else {
        validations[i].width = false;
      }

      if (pack.length <= 0) {
        isValid = false;

        validations[i].length = true;
        errMsgs.push("Length is required.");
      } else {
        validations[i].length = false;
      }

      if (pack.weight <= 0) {
        isValid = false;
        validations[i].weight = true;
        errMsgs.push("Weight is required.");
      } else {
        validations[i].weight = false;
      }

      if (pack.weight > 0 && pack.weight < 30) {
        const dec = pack.weight - parseInt(pack.weight);
        if (dec.toFixed(1) != 0.5 && dec.toFixed(1) != 0.0) {
          isValid = false;

          validations[i].weight = true;
          errMsgs.push(
            "Hãy làm tròn số thập phân .5 hoặc số nguyên. VD: 1.5 hoặc 1"
          );
        } else {
          validations[i].weight = false;
        }
      } else {
        const dec = pack.weight - parseInt(pack.weight);
        if (dec.toFixed(1) != 0.0) {
          isValid = false;

          validations[i].weight = true;
          errMsgs.push("Hãy làm tròn số nguyên. VD: 30.5 -> 30 hoặc 31");
        } else {
          validations[i].weight = false;
        }
      }

      validations[i].errMsg = errMsgs.join(" ");
    });

    this.setState((prevState) => ({
      ...prevState,
      packsDetailsValidation: validations,
    }));

    return isValid;
  }

  validatePackOnPackInfoChange(name, value, i){
    let validations = this.state.packsDetailsValidation;

    if (name === 'quantity' && value <= 0) {
      validations[i].quantity = true;
    } else {
      validations[i].quantity = false;
    }

    if (name === 'height' && value <= 0) {
      validations[i].height = true;
    } else {
      validations[i].height = false;
    }

    if (name ==='width' && value <= 0) {
      validations[i].width = true;
    } else {
      validations[i].width = false;
    }

    if (name === 'length' && value <= 0) {
      validations[i].length = true;
    } else {
      validations[i].length = false;
    }

    if (name === 'weight' && value <= 0) {
      validations[i].weight = true;
    } else {
      validations[i].weight = false;
    }

    this.setState((prevState) => ({
      ...prevState,
      packsDetailsValidation: validations,
    }));

  }

  onChangeInvoice(e, i) {
    const { name, value } = e.target;
    const list = [...this.state.packInvoiceList];
    if (name === "receiverCountry") {
      // value is country object
      list[i][name] = value.name;
    } else {
      list[i][name] = value;
    }

    list[i]["subTotal"] = list[i]["quantity"] * list[i]["unitPrice"]; // calculate subTotal

    // calculate invoice value
    let invVal = 0;
    if (name === "packInvoiceValue") {
      invVal = value;
    } else {
      invVal = (
        list.reduce(function(a, b) {
          return a + parseFloat(b["subTotal"]);
        }, 0) || 0
      ).toFixed(1);
    }

    this.setState((prevState) => ({
      packInvoiceList: list,
      billInfo: {
        ...prevState.billInfo,
        invoices: JSON.stringify(list).replaceAll(`\"`, `\\"`),
        packInvoiceValue: invVal,
      },
    }));
  }

  onRemoveRecord = (index) => {
    const list = [...this.state.packList];

    confirmAlert({
      title: "Delete Confirmation",
      message: "Are you sure to delete this Pack?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            list.splice(index, 1);
            this.setState({ packList: list });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  onRemovePackInvoiceRecord = (index) => {
    const list = [...this.state.packInvoiceList];

    confirmAlert({
      title: "Delete Confirmation",
      message: "Are you sure to delete this Invoice?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            list.splice(index, 1);
            this.setState({ packInvoiceList: list });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  addPackage = () => {
    this.setState((prevState) => ({
      packList: [
        ...prevState.packList,
        {
          quantity: "",
          type: "",
          length: "",
          width: "",
          height: "",
          weight: "",
          convertRatio: "",
          chargedWeight: "",
          images: [],
        },
      ],
      packsDetailsValidation: [
        ...prevState.packsDetailsValidation,
        {
          errMsg: "",
          quantity: false,
          length: false,
          width: false,
          height: false,
          weight: false,
        },
      ],
    }));
  };

  showTPCode = (code) => {
    code = code?.toString()
    return code?.startsWith('92') ? code : 'TP' + code
  }

  addPackageInvoice = () => {
    this.setState((prevState) => ({
      packInvoiceList: [
        ...prevState.packInvoiceList,
        {
          description: "",
          unit: "",
          quantity: "",
          unitPrice: "",
          subTotal: "",
        },
      ],
    }));
  };

  validateBillInfo(info) {
    let validationState = this.state.billValidation;
    let errors = [];
    if (info.senderCompanyName === "") {
      validationState.senderCompanyName = true;
      errors.push("your company name");
    } else validationState.senderCompanyName = false;
    if (info.senderName === "") {
      validationState.senderName = true;
      errors.push("your name");
    } else validationState.senderName = false;
    if (info.senderPhone === "") {
      validationState.senderPhone = true;
      errors.push("your phone");
    } else  validationState.senderPhone = false;
    if (this.state.currUser && this.state.currUser.address === "") {
      validationState.senderAddress = true;
      errors.push("your company's address");
    } else validationState.senderAddress = false;
    if (info.senderEmail !== "" && !validator.isEmail(info.senderEmail)) {
      validationState.senderEmail = true;
      errors.push("sender email");
    } else validationState.senderEmail = false;

    // if (info.receiverCompanyName === "") {
    //   validationState.receiverCompanyName = true;
    //   errors.push("receiver company's name");
    // } else validationState.receiverCompanyName = false;
    if (info.receiverName === "") {
      validationState.receiverName = true;
      errors.push("receiver name");
    } else validationState.receiverName = false;
    if (info.receiverPhone === "") {
      validationState.receiverPhone = true;
      errors.push("receiver phone");
    } else validationState.receiverPhone = false;
    if (info.receiverCountry === "") {
      validationState.receiverCountry = true;
      errors.push("receiver country");
    } else validationState.receiverCountry = false;
    if (info.receiverPostalCode === "") {
      validationState.receiverPostalCode = true;
      errors.push("receiver postal code");
    } else validationState.receiverPostalCode = false;
    if (info.receiverCity === "") {
      validationState.receiverCity = true;
      errors.push("receiver city");
    } else validationState.receiverCity = false;
    // if (info.receiverAddr1 === "") {
    //   validationState.receiverAddr1 = true;
    //   errors.push("receiver address 1");
    // } else validationState.receiverAddr1 = false;
    // if (info.receiverAddr2 === "") {
    //   validationState.receiverAddr2 = true;
    //   errors.push("receiver address 2");
    // } else validationState.receiverAddr2 = false;
    // if (info.packageType === PACK_TYPE && info.packContent === "") {
    //   validationState.packContent = true;
    //   errors.push("package name");
    // } else validationState.packContent = false;

    if (info.logisticService === "") {
      validationState.logisticService = true;
      errors.push("logistic service");
    } else validationState.logisticService = false;

    // if (info.packageType === DOC_TYPE && info.docCount <= 0) {
    //   validationState.docCount = true;
    //   errors.push("doc count");
    // } else validationState.docCount = false;
    // if (info.packageType === DOC_TYPE && info.docWeight < 0.01) {
    //   validationState.docWeight = true;
    //   errors.push("doc weight");
    // } else validationState.docWeight = false;

    if (errors.length > 0) {
      validationState.errMsg = "Please input valid " + errors.join(", ");
      this.setState({ billValidation: validationState });
      return false;
    }
    return true;
  }

  validateBillOnInfoChange(name, value){
    let validationState = this.state.billValidation;

    let arrStr = ['senderCompanyName', 'senderName', 'senderPhone', 'receiverCompanyName', 'receiverName', 'receiverPhone', 'receiverCountry',
      'receiverPostalCode', 'receiverCity', 'receiverAddr1', 'receiverAddr2', 'logisticService' ];
    if (arrStr.indexOf(name) && value === "") {
      validationState[name] = true;
    } else validationState[name] = false;

    if (name === 'senderEmail' && value !== "" && !validator.isEmail(value)) {
      validationState.senderEmail = true;
    } else validationState.senderEmail = false;

    if (name === 'docCount' && value <= 0) {
      validationState.docCount = true;
    } else validationState.docCount = false;
    // if (name === 'docWeight' && value < 0.01) {
    //   validationState.docWeight = true;
    // } else validationState.docWeight = false;

    this.setState((prevState) => ({
      ...prevState,
      billValidation: validationState,
    }));
  }

  async onSubmit(e) {
    if (this.state.disableSubmitBtn) return
    this.setState({
      disableSubmitBtn: true,
    });
    if (
      !this.validatePacksDetails(this.state.packList) &&
      this.state.packageType === PACK_TYPE
    ) {
      for (const validation of this.state.packsDetailsValidation) {
        if (validation.errMsg !== "") {
          toast.error(validation.errMsg);
        }
      }
      this.setState({isOpenTerm: true})
      this.setState({
        disableSubmitBtn: false,
      });
      return;
    }

    if (!this.validateBillInfo(this.state.billInfo)) {
      toast.error(this.state.billValidation.errMsg);
      this.setState({
        disableSubmitBtn: false,
      });
      return;
    }
    // check role admin, manager, tư vấn viên, sale required selectedCustomerKH.id
    if (this.state.currUser.role === USER_ROLE.ADMIN
      || this.state.currUser.role === USER_ROLE.MANAGER
      || this.state.currUser.role === USER_ROLE.SALER
      || this.state.currUser.role === USER_ROLE.CONSULTANT) {
      if (!this.state.selectedCustomerKH.id) {
        toast.error('Vui lòng chọn khách hàng');
        this.setState({disableSubmitBtn: false,})
        return
      }
    }


    const { checkDk } = this.state
    if (!checkDk) {
      this.setState({isOpenTerm: true})
      this.setState({
        disableSubmitBtn: false,
      });
      return;
    }

    let { kgCode } = this.state.billInfo
    console.log('kgCode ', kgCode)
    if (kgCode) {
      try {
        const { data } = await axios.post(`/get-billup/${kgCode}`)
        if (!data) {
          this.setState({tpValid: false, disableSubmitBtn: false})
          return
        }
        this.setState({tpValid: true, billupInfo: data})
      } catch (e) {
      }
    } else {
      this.setState({tpValid: false, disableSubmitBtn: false,})
      const { data } = await axios.get(`/package-latest-ops`)
      console.log('data', data)
      let opsCode = data?.kgCode?.replace('TP', '')
      // check opsCode include 9288 in the first
      if (opsCode && opsCode.startsWith('9288')) {
        opsCode = opsCode.replace('9288', '')
      }
      if (opsCode && opsCode.startsWith('92')) {
        opsCode = opsCode.replace('92', '')
      }

      opsCode = opsCode ? parseInt(opsCode) + 1 : '1888000'
      if (this.state.currUser.role === USER_ROLE.OPS) {
        kgCode = '92' + opsCode
      } else {
        kgCode = '9288' + opsCode
      }
      this.setState({billInfo: {...this.state.billInfo, kgCode}})
    }
    // else {
    //   this.setState({tpValid: false, disableSubmitBtn: false,})
    //   return
    // }

    if (kgCode) {
      const { data: isExist } = await axios.get(`/package-by-tp-code/${kgCode}`)
      if (isExist) {
        toast.error('Đơn hàng đã tồn tại trong hệ thống');
        this.setState({disableSubmitBtn: false,})
        return
      }
    }

    this.setState({
      validDkMess: "",
      billValidation: this.initBillValidation,
      disableSubmitBtn: true,
    });

    // upload image file in the packList
    // check PACK_TYPE
    if (this.state.billInfo.packageType === PACK_TYPE) {
      const list = [...this.state.packList]
      const formData = new FormData()
      for (let i = 0; i < list.length; i++) {
        console.log(list[i]?.images)
        formData.append('photos', list[i]?.images)
      }
      try {
        const {data = []} = await axios({
          method: "post",
          url: "/upload-pickup",
          data: formData,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });

        // update images to packList

        let imageArr = []
        for (let i = 0; i < list.length; i++) {
          if (list[i]?.images && typeof list[i]?.images === 'object') {
            imageArr.push(data[i])
          } else {
            imageArr.push('')
            data.unshift('')
          }
        }
        for (let i = 0; i < list.length; i++) {
          list[i]["images"] = imageArr[i]
        }

        // update packList
        this.setState({
          billInfo: {
            ...this.state.billInfo,
            packs: JSON.stringify(list).replaceAll(`\"`, `\\"`),
          },
        })
      } catch (e) {
        console.log('error', e)
      }
    }

    if (this.state.selectedUser) {
      this.props.dispatch(createPackage({...this.state.billInfo, owner: this.state.billupInfo?.owner || this.state.currUser?.displayName, companyUserID: this.state.selectedUser}));
    } else {
      this.props.dispatch(createPackage({...this.state.billInfo, owner: this.state.billupInfo?.owner || this.state.currUser?.displayName}));
    }
    setTimeout(() => {
      this.setState({disableSubmitBtn: false})
    }, 1000);
    // await axios.post(`/update-billup/${kgCode}`, {statusType: 'Đã làm chứng từ'})
    // check role ops
    try {
      await axios.post(`/create-packageinfo`, {
        kgCode: kgCode,
        totalPrice: this.state.createBillup.totalPrice,
        group: this.state.createBillup.group,
        bhPrice: this.state.createBillup.bhPrice,
        paymentType: this.state.createBillup.paymentType,
        note: this.state.createBillup.note,
        owner: this.state.billupInfo?.owner || this.state.currUser?.displayName,
        customerId: this.state.selectedCustomerKH.id || null,
        weight: this.state.createBillup.weight,
        weight2: this.state.createBillup.weight2,
      })
    } catch (e) {
      console.log(e)
    }


    if (!this.state.selectedCustomer.id) {
      // create new customer
      try {
        if (this.state.selectedCustomerKH.id) {
          await axios.post(`/create-customer-receiver`, {
            receiverCompanyName: this.state.billInfo.receiverCompanyName,
            receiverName: this.state.billInfo.receiverName,
            receiverPhone: this.state.billInfo.receiverPhone,
            receiverAddr1: this.state.billInfo.receiverAddr1,
            receiverPostalCode: this.state.billInfo.receiverPostalCode,
            receiverCity: this.state.billInfo.receiverCity,
            receiverProvince: this.state.billInfo.receiverProvince,
            note: 'Tạo từ hệ thống',
            customerId: this.state.selectedCustomerKH.id || null,
            owner: this.state.currUser?.displayName,
            // images: this.state.images.map(e => e.data_url).join(',')
          })
        }
      } catch (e) {
        console.log(e)
      }
    }

    // try {
    //   if (this.state.billupInfo?.owner) {
    //     const { data: user} = await axios.get(`/user-by-name/${this.state.billupInfo.owner}`)
    //     if (user && (user.role !== 'ops')) {
    //       await axios.post(`/send-gapo`, {
    //         requestId: kgCode,
    //         senderPhone: this.state.billupInfo.customerPhone,
    //         receiverPhone: this.state.billupInfo.receivePhone,
    //         ngayTao: moment(this.state.billupInfo.createdAt).format('DD/MM/YYYY'),
    //         tenkh: this.state.billupInfo.receiveName,
    //         type: "sending"
    //       })
    //     }
    //   }
    //
    // } catch (e) {
    //   console.log(e)
    // }

    // setTimeout 2000
    setTimeout(() => {
      this.props.history.push("/app/packet");
    }, 200);

    e.preventDefault();
  }

  async componentDidMount() {
    let token = localStorage.getItem("id_token");
    let currUser = jwt.decode(token);
    this.setState({ currUser: currUser, currUser2: currUser });
    const termData = await fetch('/term.txt').then((r) => r.text())
    await this.setState({ termData })
    let newState = this.state.billInfo;
    newState.companyUserID = currUser.id;
    console.log(currUser)


    if (currUser.role === USER_ROLE.OPS) {
      this.setState({tpValid: false})
      newState.senderCompanyName = currUser.companyName;
      newState.senderName = currUser.displayName;
      newState.senderContact = currUser.address;
      newState.senderPhone = currUser.phone;
      newState.senderEmail = currUser.email;
    } else {
      newState.senderCompanyName = 'TIN PHAT EXPRESS CO.,LTD';
      newState.senderName = 'MR TIN PHAT';
      newState.senderContact = '53 Hồng Hà, Phường 2, Quận Tân Bình, TP.HCM';
      newState.senderPhone = '0853491096';
      newState.senderEmail = currUser.email;
    }
    this.setState({ billInfo: newState });

    this.getAllService()
    await this.getListCustomerKH()
    // this.onSelectedCustomerKH(this.state.listCustomerKH[0])
    await this.getListCustomer()
    this.onSelectedCustomer(this.state.listCustomer[0])
  }

  onSelectedCustomer = (customer) => {
    if (customer) {
      const temp = customer
      if (temp) {
        const newState = this.state.billInfo
        newState.receiverCompanyName = temp.receiverCompanyName
        newState.receiverName = temp.receiverName
        newState.receiverPhone = temp.receiverPhone
        newState.receiverAddr1 = temp.receiverAddr1
        newState.receiverPostalCode = temp.receiverPostalCode
        newState.receiverCity = temp.receiverCity
        newState.receiverProvince = temp.receiverProvince
        newState.receiverCountry = temp.receiverCountry
        this.setState({billInfo: newState, selectedCustomer: temp})
      }
    }
  }
  onSelectedCustomerKH = (customer) => {
    if (customer) {
      const {id} = customer
      const temp = this.state.listCustomerKH.filter(e => e.id == id)?.[0]
      if (temp) {
        this.setState({selectedCustomerKH: temp})
        this.getListCustomer({search: this.state.search})
      }
    }
  }


  handleInputChange(e) {
    const inputValue = e;
    // Clear any existing timer
    clearTimeout(timerId);
    let self = this
    // Set a new timer to delay the API call
    timerId = setTimeout(() => {
      this.setState({search: inputValue})
      self.getListCustomer({ search: inputValue });
    }, 500);
  }
  handleInputChangeKH(e) {
    const inputValue = e;
    // Clear any existing timer
    clearTimeout(timerId);
    let self = this
    // Set a new timer to delay the API call
    timerId = setTimeout(() => {
      self.getListCustomerKH({ searchKH: inputValue });
    }, 500);
  }

  async getListCustomer(params = { search: ''}) {
    try {
      let url = `/get-list-customer-receiver?page=${params.page || 1}&size=10&search=${params.search}`
      if (this.state.selectedCustomerKH) {
        url += `&customerId=${this.state.selectedCustomerKH.id || ''}`
      }
      const res = await axios.get(url,)
      if (res && res.data) {
        const list = res.data.list.map(i => {
          return {
            ...i,
            value: i.id,
            label: `${i.receiverName}`
          }
        })
        this.setState({listCustomer: list})
        // this.onSelectedCustomer(list[0])

      }
    } catch (e) {
      console.error(e)
    }
  }
  async getListCustomerKH(params = { searchKH: ''}) {
    try {
      let url = `/get-list-customer?page=${params.page || 1}&size=10&search=${params.searchKH}`
      const res = await axios.get(url,)
      if (res && res.data) {
        const list = res.data.list.map(i => {
          return {
            ...i,
            value: i.id,
            label: `${i.name}`
          }
        })
        this.setState({listCustomerKH: list})
        // this.onSelectedCustomer(list[0])

      }
    } catch (e) {
      console.error(e)
    }
  }

  handlePackageTypeChange(e) {
    let newBillInfo = this.state.billInfo;
    newBillInfo.packageType = e.target.value;
    this.setState(newBillInfo);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isCreating && !this.props.isCreating) {
      if (this.props.type === CREATE_PACKAGE_FAILURE) {
        toast.error(this.props.errMsg);
      } else if (this.props.type === CREATE_PACKAGE_SUCCESS) {
        toast.success("Success! Created bill");
        // setTimeout(() => {
        //   window.location.reload(false);
        // }, 3000)
      }
    }
  }

  getInvoiceVal(e) {
    this.setState((prevState) => ({
      billInfo: {
        ...prevState.billInfo,
        packInvoiceValue: this.state.packInvoiceList.reduce(function(a, b) {
          return a + parseFloat(b["subTotal"]);
        }, 0),
      },
    }));
  }
  toggleChange = async () => {
    await this.setState({
      checkDk: !this.state.checkDk,
    });
    if (this.state.checkDk) {
      this.setState({
        isOpenTerm: false,
      });
    }
  }

  onImageChange = (e, i) => {
    const { name, files } = e.target;
    const list = [...this.state.packList];
    list[i][name] = e.target.files[0];
    this.setState({packList: list});
  }

  onCreateBillupChange(e) {
    const {name, value} = e.target;
    let createBillupInfo = this.state.createBillup
    createBillupInfo[name] = value

    if (name === 'totalPrice') {
      let billInfo = this.state.billInfo
      billInfo.totalPrice = value
      this.setState({billInfo})
    }
    this.setState({createBillup: createBillupInfo})
  }
  render() {
    const paymentTypeOption = {
      CASH: "Tiền mặt",
      BANK: "Chuyển khoản",
      BANK2: "Tiền mặt và Chuyển khoản",
    }
    const groupTypeOption = {
      CASH: "Hàng thường",
      BANK: "Hàng khó",
    }
    const statusTypeOption = {
      PROGRESS: "Chưa làm chứng từ",
      DONE: 'Đã làm chứng từ'
    }
    const statusPaymentOption = {
      NOTYET: "Chưa thanh toán",
      PAID: 'Đã thanh toán',
      DEBT: 'Công nợ'
    }
    const {isOpenTerm, billupInfo = ''} = this.state
    var packTable = "";
    if (this.state.billInfo.packageType === PACK_TYPE) {
      packTable = (
        <div>
          <Row>
            <Col sm="12" md={{ size: 10, offset: 1 }}>
              <Widget
                title={
                  <h4>
                    {" "}
                    <span className="fw-semi-bold">PACK DETAILS</span>
                  </h4>
                }
                settings
                close
              >
                <Row>
                  <Col>
                    <p style={{ color: "red" }}>
                      {" "}
                      *Chú ý: Gross Weight và Dimension phải được làm tròn
                    </p>
                    <p>
                      - Làm tròn 0,5kg đối với những kiện hàng dưới 30kg.
                      <br />
                      Ví dụ: 25.1kg làm tròn 25.5kg hoặc 25.6kg làm tròn 26kg
                      <br />
                      - Làm tròn 1kg đối với những kiện hàng trên 30kg
                      <br />
                      Ví dụ: 30.1kg -{">"} 30.9kg làm tròn 31kg
                    </p>
                  </Col>
                  <Col className="text-right">
                    <Label>
                      {" "}
                      Total Number of Packs: &nbsp;{" "}
                      {this.state.packList.reduce(function(a, b) {
                        return a + parseInt(b["quantity"]);
                      }, 0) || 0}
                    </Label>{" "}
                    <br />
                    <Label>
                      {" "}
                      Total Charged Weight: &nbsp;{" "}
                      {Rounding(
                        this.state.packList.reduce(function(a, b) {
                          return a + parseFloat(b["chargedWeight"]);
                        }, 0)
                      ) || 0}{" "}
                    </Label>{" "}
                    <br />
                    <br />
                    {this.state.packsDetailsValidation.packs ? (
                      <p style={{ color: "red" }}>
                        {" "}
                        *Please input valid package details
                      </p>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Table
                  responsive
                  className="table-bordered table-sm"
                  width="100%"
                >
                  <thead className="text-uppercase table-light">
                  <tr>
                    <th className="text-center">Quantity</th>
                    <th className="text-center">Type</th>
                    <th className="text-center">Length (cm)</th>
                    <th className="text-center">Width (cm)</th>
                    <th className="text-center">Height (cm)</th>
                    <th className="text-center">Weight (kg)</th>
                    <th className="text-center">Converted Weight</th>
                    <th className="text-center">Charged Weight</th>
                    <th className="text-center">Upload Image</th>
                    <th className="text-center">Delete</th>
                  </tr>
                  </thead>
                  <tbody>
                  {this.state.packList.map((x, i) => {
                    return (
                      <tr>
                        <td className="text-center">
                          <div className="input-group input-group-sm">
                            <Input
                              className="form-control quantity"
                              name="quantity"
                              value={x.quantity}
                              invalid={
                                this.state.packsDetailsValidation[i].quantity
                              }
                              type="number"
                              id="quantity"
                              onChange={(e) => this.onPackInfoChange(e, i)}
                            />
                          </div>
                        </td>
                        <td>
                          <select
                            name="type"
                            className={s.selectCustom}
                            aria-label="Default select example"
                            onChange={(e) => this.onPackInfoChange(e, i)}
                          >
                            <option value="" defaultValue>
                              Loại
                            </option>
                            <option value="carton">Carton</option>
                            <option value="pallet">Pallet</option>
                            <option value="tui">Túi (Phong bì)</option>
                          </select>
                        </td>

                        <td className="text-center">
                          <div className="input-group input-group-sm ">
                            <Input
                              name="length"
                              value={x.length}
                              type="number"
                              id="chieudai"
                              invalid={
                                this.state.packsDetailsValidation[i].length
                              }
                              onChange={(e) => this.onPackInfoChange(e, i)}
                            />
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="input-group input-group-sm ">
                            <Input
                              name="width"
                              value={x.width}
                              type="number"
                              id="chieurong"
                              invalid={
                                this.state.packsDetailsValidation[i].width
                              }
                              onChange={(e) => this.onPackInfoChange(e, i)}
                            />
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="input-group input-group-sm ">
                            <Input
                              name="height"
                              value={x.height}
                              type="number"
                              id="chieucao"
                              invalid={
                                this.state.packsDetailsValidation[i].height
                              }
                              onChange={(e) => this.onPackInfoChange(e, i)}
                            />
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="input-group input-group-sm">
                            <Input
                              type="number"
                              name="weight"
                              value={x.weight}
                              id="trongluong"
                              invalid={
                                this.state.packsDetailsValidation[i].weight
                              }
                              onChange={(e) => this.onPackInfoChange(e, i)}
                            />
                          </div>
                        </td>
                        <td className="text-center">
                          <label id="tlquydoi"> {x.convertRatio} </label>
                        </td>
                        <td className="text-center">
                          <label id="chargedweight">
                            {" "}
                            {x.chargedWeight}{" "}
                          </label>
                        </td>
                        <td className="text-center">
                          <div className="input-group input-group-sm">
                            <input
                              type="file"
                              name="images"
                              id="images"
                              accept="image/*"
                              onChange={(e) =>
                                this.onImageChange(e, i, "packList")
                              }
                            />
                          </div>
                        </td>
                        <td className="text-center">
                          <button
                            type="button"
                            className="btn btn-default"
                            aria-label="glyphicon-trash"
                            onClick={() => this.onRemoveRecord(i)}
                          >
                              <span
                                className="glyphicon glyphicon-trash"
                                aria-hidden="true"
                              ></span>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                  </tbody>
                </Table>
                <div className="text-center d-flex align-content-center justify-content-center">
                  <Button
                    type="button"
                    id="plus"
                    className="btn btn-default btn-add"
                    aria-label="Left Align"
                    onClick={this.addPackage}
                  >
                    <i class="fa fa-plus-circle"></i>
                    Thêm Kiện hàng
                  </Button>
                </div>
              </Widget>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md={{ size: 10, offset: 1 }}>
              <Widget
                title={
                  <h4>
                    {" "}
                    <span className="fw-semi-bold">Invoice</span>
                  </h4>
                }
                settings
                close
              >
                <FormGroup name="invoiceExportFormat" className="form-inline">
                  <Label id="invoiceExportFormat" name="invoiceExportFormat">
                    Export as: &nbsp;{" "}
                  </Label>
                  <Input
                    placeholder="Choose"
                    name="invoiceExportFormat"
                    className="form-control"
                    type="text"
                    onChange={(e) => this.onInfoChange(e)}
                    list="invoiceExportForms"
                  />
                  <datalist id="invoiceExportForms">
                    <option value="Gift (no commercial value)" />
                    <option value="Sample" />
                    <option value="Other" />
                  </datalist>
                </FormGroup>
                <Table responsive className="table-bordered table-sm mb-0">
                  <thead className="text-uppercase table-light">
                  <tr>
                    <th className="text-center">
                      Goods Details
                      <br />
                      (Product names, materials, stamps, ...)
                    </th>
                    <th className="text-center">Quantity</th>
                    <th className="text-center">Unit</th>
                    <th className="text-center">Price</th>
                    <th className="text-center">Total Value</th>
                    <th className="text-center">Delete</th>
                  </tr>
                  </thead>
                  <tbody>
                  {this.state.packInvoiceList.map((x, i) => {
                    return (
                      <tr>
                        <td className="text-center">
                          <div className="input-group">
                            <Input
                              name="description"
                              value={x.description}
                              type="textarea"
                              className="description"
                              id="description"
                              onChange={(e) => this.onChangeInvoice(e, i)}
                            />
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="input-group flex-nowrap">
                            <Input
                              name="quantity"
                              value={x.quantity}
                              type="number"
                              className="quantity"
                              id="quantity"
                              placeholder="quantity"
                              onChange={(e) => this.onChangeInvoice(e, i)}
                            />
                          </div>
                        </td>
                        <td>
                          <Input
                            placeholder="Choose"
                            name="unit"
                            className="form-control"
                            type="text"
                            onChange={(e) => this.onChangeInvoice(e, i)}
                            list="unitList"
                          />
                          <datalist id="unitList">
                            <option value="Bag" />
                            <option value="Pcs" />
                            <option value="Box" />
                            <option value="Jar" />
                            <option value="Set" />
                            <option value="Other" />
                          </datalist>
                        </td>
                        <td className="text-center">
                          <div className="input-group flex-nowrap">
                            <Input
                              name="unitPrice"
                              value={x.unitPrice}
                              type="number"
                              className="unitPrice"
                              id="unitPrice"
                              onChange={(e) => this.onChangeInvoice(e, i)}
                            />
                          </div>
                        </td>
                        <td name="subTotal" className="text-center">
                          {parseFloat(x.subTotal || 0).toFixed(1)}
                        </td>
                        <td className="text-center">
                          <button
                            type="button"
                            className="btn btn-default"
                            aria-label="glyphicon-trash"
                            onClick={() => this.onRemovePackInvoiceRecord(i)}
                          >
                              <span
                                className="glyphicon glyphicon-trash"
                                aria-hidden="true"
                              ></span>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                  </tbody>
                </Table>

                <br />
                <div className="text-center">
                  <Button
                    type="button"
                    id="plus"
                    className="btn btn-default"
                    aria-label="Left Align"
                    onClick={this.addPackageInvoice}
                  >
                    <span
                      className="glyphicon glyphicon-plus"
                      aria-hidden="true"
                    ></span>
                    Thêm
                  </Button>
                </div>
              </Widget>
            </Col>
          </Row>
        </div>
      );
    }

    return (
      <div>
        <Modal isOpen={isOpenTerm} backdrop="static" size="xl">
          <ModalHeader className="text-danger text-uppercase">điều khoản sử dụng dịch vụ</ModalHeader>
          <ModalBody>
            { ReactHtmlParser (this.state.termData) }
          </ModalBody>
          <ModalFooter>

            <div className="container mt-3 d-flex justify-content-center ">
              <div className="form-check mb-3">
                <div>
                  {' '}
                  <input
                    name="checkDk"
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    defaultChecked={this.state.checkDk}
                    onChange={this.toggleChange}
                  />
                </div>
                <label className="form-check-label" htmlFor="exampleCheck1">
                  Tôi đã đọc, hiểu và đồng ý với các điều khoản sử dụng dịch vụ
                </label>
                <small className="form-text  text-danger">
                  {this.state.validDkMess}
                </small>
              </div>
            </div>
          </ModalFooter>
        </Modal>
        <Form className="needs-validation">
          <div className={s.root} id="BillForm">
            <Breadcrumb>
              <BreadcrumbItem>TRANG</BreadcrumbItem>
              <BreadcrumbItem active>Hoá đơn New</BreadcrumbItem>
            </Breadcrumb>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
            />
            <Row lg="3" md="2" xs="1">
              <Col lg="3" className="mb-4">
                <Card>
                  <CardHeader tag="h5">Thông tin Người gửi (Sender)</CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label for="senderCompanyName">Công ty (Company Name) <span className={s.required}>*</span></Label>
                      <Input id="senderCompanyName" name="senderCompanyName" type="text" value={this.state.billInfo.senderCompanyName}
                             invalid={this.state.billValidation.senderCompanyName} onChange={(e) => this.onInfoChange(e)} />
                      <FormFeedback>{this.state.billValidation.senderCompanyName&&"Please input valid company name"}</FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label for="senderName">Người LH (Contact Name) <span className={s.required}>*</span></Label>
                      <Input id="senderName" name="senderName" type="text" value={this.state.billInfo.senderName} invalid={this.state.billValidation.senderName} onChange={(e) => this.onInfoChange(e)} />
                      <FormFeedback>{this.state.billValidation.senderName&&"Please input valid contract name"}</FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label for="senderContact">Địa chỉ Liên hệ (Contact address) <span className={s.required}>*</span></Label>
                      <Input id="senderContact" name="senderContact" type="text" value={this.state.billInfo.senderContact}
                             invalid={this.state.billValidation.senderAddress} onChange={(e) => this.onInfoChange(e)} />
                      <FormFeedback>{this.state.billValidation.senderAddress&&"Please input valid contract address"}</FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label for="senderPhone">Số Điện thoại (Telephone) <span className={s.required}>*</span></Label>
                      <Input id="senderPhone" name="senderPhone" value={this.state.billInfo.senderPhone} type="text" invalid={this.state.billValidation.senderPhone}
                             onChange={(e) => this.onInfoChange(e)} />
                      <FormFeedback>{this.state.billValidation.senderPhone&&"Please input valid phone"}</FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label for="senderEmail">Email</Label>
                      <Input id="senderEmail" name="senderEmail" value={this.state.billInfo.senderEmail} type="email" invalid={this.state.billValidation.senderEmail}
                             onChange={(e) => this.onInfoChange(e)} />
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" md="12" className="mb-4">
                <Card>
                  <CardHeader tag="h5">Thông tin Người nhận (Receiver)</CardHeader>
                  <CardBody>
                    {
                      (this.state.currUser.role !== USER_ROLE.OPS &&
                      this.state.currUser.role !== USER_ROLE.DOCUMENT) && (
                        <FormGroup>
                          <Label for="logisticService">Chọn Khách Hàng</Label>
                          <Select
                            options={this.state.listCustomerKH}
                            value={this.state.selectedCustomerKH[0]?.id}
                            onChange={this.onSelectedCustomerKH}
                            onInputChange={this.handleInputChangeKH}
                            isSearchable
                            autosize={false}/>
                        </FormGroup>
                      )
                    }

                    <FormGroup>
                      <Label for="logisticService">Chọn người nhận cũ</Label>
                      <Select
                        options={this.state.listCustomer}
                        value={this.state.selectedCustomer[0]?.id}
                        onChange={this.onSelectedCustomer}
                        onInputChange={this.handleInputChange}
                        isSearchable
                        autosize={false}/>
                    </FormGroup>
                    <Row>
                      <Col lg="6" md="6" sm="12">
                        <FormGroup>
                          <Label for="receiverCompanyName">Công ty (Company Name) </Label>
                          <Input id="receiverCompanyName" name="receiverCompanyName" value={this.state.billInfo.receiverCompanyName} type="text" invalid={this.state.billValidation.receiverCompanyName} onChange={(e) => this.onInfoChange(e)} />
                          <FormFeedback>{this.state.billValidation.receiverCompanyName&&"Please input valid receiver company's name"}</FormFeedback>
                        </FormGroup>

                        <FormGroup>
                          <Label for="receiverName">Người Liên hệ (Contact Name) <span className={s.required}>*</span></Label>
                          <Input id="receiverName" name="receiverName" type="text" value={this.state.billInfo.receiverName} invalid={this.state.billValidation.receiverName} onChange={(e) => this.onInfoChange(e)} />
                          <FormFeedback>{this.state.billValidation.receiverName&&"Please input valid receiver name"}</FormFeedback>
                        </FormGroup>

                        <FormGroup>
                          <Label for="receiverPhone">Số Điện thoại (Telephone) <span className={s.required}>*</span></Label>
                          <Input id="receiverPhone" name="receiverPhone" type="text"  value={this.state.billInfo.receiverPhone} invalid={this.state.billValidation.receiverPhone} onChange={(e) => this.onInfoChange(e)} />
                          <FormFeedback>{this.state.billValidation.receiverPhone&&"Please input valid receiver phone"}</FormFeedback>
                        </FormGroup>

                        <FormGroup>
                          <Label for="receiverCountry">Quốc gia (Country) <span className={s.required}>*</span></Label>
                          <Input id="receiverCountry" name="receiverCountry" type="select" value={this.state.billInfo.receiverCountry}
                                 invalid={this.state.billValidation.receiverCountry} onChange={(e) => this.onInfoChange(e)} >
                            {Country.getAllCountries().map((country) => (
                              <option defaultValue={country.isoCode === "VN"}>
                                {country.name + " - " + country.isoCode}
                              </option>
                            ))}
                            {Country.getAllCountries().find((country) => country.isoCode === this.state.billInfo.receiverCountry) === undefined && (
                              <option defaultValue>{this.state.billInfo.receiverCountry}</option>
                            )}
                          </Input>
                          <FormFeedback>{this.state.billValidation.receiverCountry&&"Please input valid receiver country"}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col lg="6" md="6" sm="12">
                        <FormGroup>
                          <Label for="receiverPostalCode">Mã Bưu chính (Postal code) <span className={s.required}>*</span></Label>
                          <Input id="receiverPostalCode" name="receiverPostalCode"
                                 value={this.state.billInfo.receiverPostalCode}
                                 type="text" invalid={this.state.billValidation.receiverPostalCode} onChange={(e) => this.onInfoChange(e)} />
                          <FormFeedback>{this.state.billValidation.receiverPostalCode&&"Please input valid receiver postal code"}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                          <Label for="receiverCity">Thành phố (City) <span className={s.required}>*</span></Label>
                          <Input
                            type="text"
                            className="form-control flex-fill"
                            name="receiverCity"
                            placeholder="Thành phố (City)"
                            value={this.state.billInfo.receiverCity}
                            onChange={(e) => this.onInfoChange(e)}
                            invalid={this.state.billValidation.receiverCity}
                            list="cityList"
                          />
                          <datalist id="cityList">
                            {this.state.billInfo.receiverCountry?.split(" - ") &&
                              City.getCitiesOfCountry(
                                this.state.billInfo.receiverCountry?.split(
                                  " - "
                                )[1]
                              ).map((city) => <option>{city.name}</option>)}
                          </datalist>


                          {/* <Label for="receiverCity">Thành phố (City) <span className={s.required}>*</span></Label>
                        <FormFeedback>{this.state.billValidation.receiverCity&&"Please input valid receiver city"}</FormFeedback>
                        <Select id="receiverCity" name="receiverCity" value={CitiesOptions.find(o => o.value === this.state.billInfo.receiverCity)}
                        options={CitiesOptions}
                        styles={{
                          control: styles => ({
                            ...styles,
                            borderColor: this.state.billValidation.receiverCity ? 'red' : styles.borderColor,
                            height: 'calc(1.5em + 0.75rem + 2px)',
                            borderRadius: 0
                          })
                        }}
                        onChange={(value, action) => this.onSelectChange(value, action)}
                        /> */}
                          <div className={s.error}>{this.state.billValidation.receiverCity&&"Please input valid receiver city"}</div>
                        </FormGroup>

                        <FormGroup>
                          <Label for="receiverProvince">Tỉnh (State/Province)</Label>
                          <Input id="receiverProvince" name="receiverProvince"
                                 value={this.state.billInfo.receiverProvince}
                                 type="text" onChange={(e) => this.onInfoChange(e)} />
                        </FormGroup>

                        <FormGroup>
                          <Label for="receiverAddr1">Địa chỉ  <small className={s.required}>KHÔNG NHẬP POSTCODE, STATE, CITY</small></Label>
                          <Input id="receiverAddr1" name="receiverAddr1" type="text" value={this.state.billInfo.receiverAddr1} invalid={this.state.billValidation.receiverAddr1} onChange={(e) => this.onInfoChange(e)} />
                          <FormFeedback>{this.state.billValidation.receiverAddr1&&"Please input valid receiver address 1"}</FormFeedback>
                        </FormGroup>

                        <FormGroup>
                          <Label for="receiverAddr2">Địa chỉ đầy đủ</Label>
                          <Input id="receiverAddr2" name="receiverAddr2" type="text" invalid={this.state.billValidation.receiverAddr2} onChange={(e) => this.onInfoChange(e)} />
                          <FormFeedback>{this.state.billValidation.receiverAddr2&&"Please input valid receiver address 2"}</FormFeedback>
                        </FormGroup>

                        {/*<FormGroup>*/}
                        {/*  <Label for="receiverAddr3">Địa chỉ (address) 3 <small>KHÔNG NHẬP POSTCODE, STATE, CITY</small></Label>*/}
                        {/*  <Input id="receiverAddr3" name="receiverAddr3" type="text" onChange={(e) => this.onInfoChange(e)} />*/}
                        {/*</FormGroup>*/}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="3" className="mb-4">
                <Card>
                  <CardHeader tag="h5">Thông tin Đơn hàng (Shipment Info)</CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label for="logisticService">Dịch vụ vận chuyển (Services) <span className={s.required}>*</span></Label>
                      <Input
                        placeholder="Chọn hoặc nhập"
                        name="logisticService"
                        type="select"
                        id="logisticService"
                        value={this.state.billInfo.logisticService}
                        onChange={(e) => this.onInfoChange(e)}
                        list="logisticServiceList"
                      >
                      <option value="" defaultValue> -- Chọn dịch vụ -- </option>
                      {this.state.listServices?.map(i => (<option value={i.name}> {i.name} </option>))}
                      </Input>

                      <div className={s.error}>{this.state.billValidation.logisticService &&"Please input valid services"}</div>
                    </FormGroup>

                    <FormGroup>
                      <Label for="referenceCode">Ref Code</Label>
                      <div className="d-flex flex-row">
                        <Input
                          type="text"
                          id="referenceCode"
                          value={this.state.billInfo.referenceCode} name="referenceCode"
                          onChange={(e) => this.onInfoChange(e)} />
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label for="referenceCode">HAWB Code</Label>
                      <div className="d-flex flex-row">
                        <Input
                          type="text"
                          id="referenceCode"
                          value={this.state.billInfo.hawbCode} name="hawbCode"
                          onChange={(e) => this.onInfoChange(e)} />
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label for="referenceCode">TP Code</Label>
                      <div className="d-flex flex-row">
                        <Input
                          type="text"
                          id="referenceCode"
                          value={this.state.billInfo.kgCode} name="kgCode"
                          onChange={(e) => this.onInfoChange(e)} />
                        <button className="btn btn-secondary ml-1" style={{borderRadius: "8px",height: '38px'}} onClick={e => this.viewBillup(e)}>Xem Billup</button>
                      </div>
                      {
                        (!this.state.tpValid && this.state.billInfo.kgCode) && (
                          <FormFeedback style={{display: 'block'}}>TP Code không tồn tại trong hệ thống</FormFeedback>
                        )
                      }
                    </FormGroup>
                    {billupInfo && (
                      <div style={{borderColor: '#faac2e', borderWidth: '1px', borderStyle: 'solid', backgroundColor: '#fff'}} className="p-2 mb-1">
                        <Label for="referenceCode">Thông tin Bill</Label>
                        <div>
                          <strong>TP Code</strong>: {this.showTPCode(billupInfo.id)}
                          <div>
                            <p><strong>Receiver Name:</strong> {billupInfo.receiveName}</p>
                            <p><strong>Receiver Phone:</strong> {billupInfo.receivePhone}</p>
                            <p><strong>Receiver Address:</strong> {billupInfo.receiveAddress}</p>
                          </div>
                          <div>
                            <p><strong>Số kiện:</strong> {billupInfo.packs}</p>
                            <p><strong>Cân nặng:</strong> {billupInfo.weight}kg</p>
                            <p><strong>Cân nặng quy đổi:</strong> {billupInfo.weight2}kg</p>
                            <p><strong>Nhóm hàng:</strong> {billupInfo.group}</p>
                            <p><strong>Dịch vụ:</strong> {billupInfo.service}</p>
                            {
                              billupInfo.note && (
                                <p><strong>Note:</strong> {billupInfo.note}</p>
                              )
                            }
                          </div>
                          {billupInfo.info}
                        </div>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* Billup ìnfo */}
            <Card>
                  <CardHeader tag="h5">Thông tin Bill</CardHeader>
                  <CardBody>
                    <Row>
                      <Col lg="4" md="12" className="mb-4">
                        <FormGroup>
                          <Label for="packContent">Tên Hàng (content) <span className={s.required}>*</span></Label>
                          <Input type="text" id="packContent" value={this.state.billInfo.packContent} name="packContent" invalid={this.state.billValidation.packContent}
                                 onChange={(e) => this.onInfoChange(e)} />
                          <FormFeedback>{this.state.billValidation.packContent >0 && "Please input valid pack content"}</FormFeedback>
                        </FormGroup>

                        <FormGroup>
                          <Label for="moneyNumber">Cân nặng</Label>
                          <div className="input-group">
                            <Input type="number" name="weight" id="weight" value={this.state.createBillup.weight} placeholder=""
                                   onChange={this.onCreateBillupChange}/>
                            <div className="input-group-append">
                              <span className="input-group-text">(Kg) </span>
                            </div>
                          </div>

                        </FormGroup>
                        <FormGroup>
                          <Label for="moneyNumber">Cân nặng quy đổi</Label>
                          <div className="input-group">
                            <Input type="number" name="weight2" id="weight2" value={this.state.createBillup.weight2} placeholder=""
                                   onChange={this.onCreateBillupChange}/>
                            <div className="input-group-append">
                              <span className="input-group-text">(Kg) </span>
                            </div>
                          </div>
                        </FormGroup>
                        {(this.state.billInfo.packageType === PACK_TYPE && (
                            <>
                              <FormGroup>
                                <Label for="packInvoiceValue">Giá trị khai báo (invoice value)</Label>
                                <div className="input-group">
                                  <Input type="number" id="packInvoiceValue" enable="false" value={this.state.billInfo.packInvoiceValue} name="packInvoiceValue"
                                         invalid={this.state.billValidation.packInvoiceValue} onChange={(e) => this.onInfoChange(e)} />
                                  <div className="input-group-append w-50">
                                    <button
                                      className="btn btn-outline-secondary btn-sm"
                                      type="button"
                                      id="btn_gtri"
                                      onClick={(e) => this.getInvoiceVal(e)}
                                    >
                                      GET
                                    </button>
                                    <Input
                                      placeholder="Loại"
                                      name="packInvoiceUnit"
                                      className="input-group-append"
                                      type="text"
                                      value={this.state.billInfo.packInvoiceUnit}
                                      onChange={(e) => this.onInfoChange(e)}
                                      list="typeListUnit"
                                    />
                                    <datalist id="typeListUnit">
                                      {CURRENCY.map(i => (<option value={i}> {i} </option>))}
                                    </datalist>
                                  </div>
                                </div>
                              </FormGroup>
                            </>
                          )) ||
                          (this.state.billInfo.packageType === DOC_TYPE && (
                            <>
                              <FormGroup>
                                <Label for="docCount">Số Kiện (Number of Packages) <span className={s.required}>*</span></Label>
                                <Input type="number" id="docCount" value={this.state.billInfo.docCount} name="docCount" invalid={this.state.billValidation.docCount}
                                       onChange={(e) => this.onInfoChange(e)} />
                                <FormFeedback>{this.state.billValidation.docCount >0 && "Please input valid doc count"}</FormFeedback>
                              </FormGroup>

                              <FormGroup>
                                <Label for="docWeight">Cân nặng (GrossWeight) <span className={s.required}>*</span></Label>
                                <div className="input-group">
                                  <Input type="number" id="docWeight" value={this.state.billInfo.docWeight} name="docWeight" invalid={this.state.billValidation.docWeight}
                                         onChange={(e) => this.onInfoChange(e)} />
                                  <div className="input-group-append">
                                    <span className="input-group-text">(Kg) </span>
                                  </div>
                                  <FormFeedback>{this.state.billValidation.docWeight >0 && "Please input valid doc weight"}</FormFeedback>
                                </div>
                              </FormGroup>
                            </>
                          ))}

                        <FormGroup row tag="fieldset" style={{padding: "0 15px"}}>
                          <Label>Loại (Type)  &nbsp; &nbsp;</Label>
                          <FormGroup check>
                            <Input
                              name="doc"
                              type="radio"
                              value={DOC_TYPE}
                              checked={this.state.billInfo.packageType === DOC_TYPE}
                              onChange={this.handlePackageTypeChange}
                            />
                            <Label check>
                              DOC  &nbsp; &nbsp;
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Input
                              name="pack"
                              type="radio"
                              value={PACK_TYPE}
                              checked={
                                this.state.billInfo.packageType === PACK_TYPE
                              }
                              onChange={this.handlePackageTypeChange}
                            />
                            <Label check>
                              PACK
                            </Label>
                          </FormGroup>
                        </FormGroup>
                      </Col>
                      {
                        (this.state.currUser?.role !== 'ops' && this.state.currUser?.role !== 'document' ) && (
                          <Col lg="4" md="12" className="mb-4">
                            <FormGroup>
                              <Label for="moneyNumber">Số tiền thu khách</Label>
                              <Input type="number" name="totalPrice" id="totalPrice"
                                     value={this.state.billInfo.totalPrice}
                                     placeholder="Số tiền thu khách"
                                     onChange={this.onCreateBillupChange}/>
                            </FormGroup>
                            <FormGroup>
                              <Label for="moneyNumber">Số tiền bảo hiểm</Label>
                              <Input type="number" name="bhPrice" id="bhPrice" value={this.state.createBillup.bhPrice} placeholder="Số tiền bảo hiểm"
                                     onChange={this.onCreateBillupChange}/>
                            </FormGroup>
                            <FormGroup>
                              <Label for="typeGood">Nhóm hàng</Label>
                              <Input id="group" name="group"
                                     value={this.state.createBillup.group}
                                     type="select" onChange={this.onCreateBillupChange}>
                                {Object.values(groupTypeOption).map((object, i) => <option className="text-capitalize"
                                                                                           value={object}
                                                                                           key={i}>{object}</option>)}
                              </Input>
                            </FormGroup>
                          </Col>

                        )
                      }
                      {
                        (this.state.currUser?.role !== 'ops' && this.state.currUser?.role !== 'document' ) && (
                          <Col lg="4" md="12" className="mb-4">

                            {
                              (this.state.currUser?.role === 'admin' || this.state.currUser?.role === 'accountant') && (
                                <FormGroup>
                                  <Label for="paymentType">Tình trạng thanh toán</Label>
                                  <Input id="reportStatus" name="reportStatus"
                                         value={this.state.billInfo.reportStatus}
                                         type="select" onChange={this.onInfoChange}>
                                    {Object.values(statusPaymentOption).map((object, i) => <option className="text-capitalize"
                                                                                                   value={object}
                                                                                                   key={i}>{object}</option>)}
                                  </Input>
                                </FormGroup>
                              )
                            }
                            <FormGroup>
                              <Label for="paymentType">Hình thức thanh toán</Label>
                              <Input id="paymentType" name="paymentType"
                                     value={this.state.createBillup.paymentType}
                                     type="select" onChange={this.onCreateBillupChange}>
                                {Object.values(paymentTypeOption).map((object, i) => <option className="text-capitalize"
                                                                                             value={object}
                                                                                             key={i}>{object}</option>)}
                              </Input>
                            </FormGroup>
                            <FormGroup>
                              <Label for="note">Ghi chú</Label>
                              <textarea
                                className="form-control"
                                rows="4"
                                id="note"
                                value={this.state.createBillup.note}
                                placeholder="Ghi chú"
                                onChange={this.onCreateBillupChange}
                                name="note"
                              />
                            </FormGroup>
                          </Col>
                        )
                      }
                    </Row>
                  </CardBody>

                </Card>



            {packTable}
            {/*
            <Row>
              <div className="container mt-3">
                <div className="form-check d-flex justify-content-center  mb-3">
                  <div>
                    {" "}
                    <input
                      name="checkDk"
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                  </div>
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    Tôi đã đọc, hiểu và đồng ý với các{" "}
                    <Link to="/app/tou" target="_blank">
                      {" "}
                      điều khoản sử dụng dịch vụ
                    </Link>
                  </label>
                  <small className="form-text  text-danger">
                    {this.state.validDkMess}
                  </small>
                </div>
              </div>
            </Row> */}

            <div className="row">
              <div className="container mt-3">
                <div className="input-group d-flex justify-content-center mb-3">
                  <span>
                    {" "}
                    {
                      !this.state.disableSubmitBtn && (
                        <Button
                          disabled={this.state.disableSubmitBtn}
                          color="success"
                          size="lg"
                          type="button"
                          className="btn-add"
                          onClick={(e) => this.onSubmit(e)}
                        >
                          Tạo Hoá đơn
                        </Button>
                      )
                    }

                  </span>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isCreating: state.packages.isCreating,
    package: state.packages.package,
    errMsg: state.packages.message,
    type: state.packages.type,
  };
}

export default connect(mapStateToProps)(Bill);
