import React from "react";
import Quagga from "quagga";

class Scanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = { deviceId: props.deviceId, standardId: props.standardId };
    this.initQuagga = this.initQuagga.bind(this);
  }
  initQuagga() {
    if (this.state) {
      console.log("this.state: ", this.state);
      Quagga.init(
        {
          inputStream: {
            type: "LiveStream",
            constraints: {
              width: 640,
              height: 480,
              facingMode: "environment", // or user
              aspectRatio: { min: 1, max: 100 },
              deviceId: this.state.deviceId || undefined
            },
            singleChannel: true
          },
          locator: {
            patchSize: "medium",
            halfSample: true
          },
          numOfWorkers: 8,
          decoder: {
            readers: [this.state.standardId]
          },
          debug: {
            showCanvas: true,
            showPatches: true,
            showFoundPatches: true,
            showSkeleton: true,
            showLabels: true,
            showPatchLabels: true,
            showRemainingPatchLabels: true,
            boxFromPatches: {
              showTransformed: true,
              showTransformedBox: true,
              showBB: true
            }
          },
          multiple: true,
          locate: true
        },
        function (err) {
          if (err) {
            console.log("props: ", this.state);
            console.log("Error: ", err);
            return console.log("123", err);
          }
          Quagga.start();
        }
      );
    }
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.deviceId !== nextProps.deviceId ||
      this.props.standardId !== nextProps.standardId
    ) {
      console.log("this.nextProps: ", nextProps);
      this.setState(
        {
          deviceId: nextProps.deviceId,
          standardId: nextProps.standardId
        },
        () => setTimeout(() => {}, 1000)
      );
      // Quagga.setReaders([nextProps.standardId]);
      this.initQuagga();
      Quagga.onDetected(this._onDetected);
    }
  }
  componentDidMount() {
    this.initQuagga();
    Quagga.onDetected(this._onDetected);
    Quagga.onProcessed(function (result) {
      var drawingCtx = Quagga.canvas.ctx.overlay,
        drawingCanvas = Quagga.canvas.dom.overlay;

      if (result) {
        if (result.boxes) {
          drawingCtx.clearRect(
            0,
            0,
            parseInt(drawingCanvas.getAttribute("width")),
            parseInt(drawingCanvas.getAttribute("height"))
          );
          result.boxes
            .filter(function (box) {
              return box !== result.box;
            })
            .forEach(function (box) {
              Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                color: "green",
                lineWidth: 2
              });
            });
        }

        if (result.box) {
          Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
            color: "#00F",
            lineWidth: 2
          });
        }

        if (result.codeResult && result.codeResult.code) {
          Quagga.ImageDebug.drawPath(
            result.line,
            { x: "x", y: "y" },
            drawingCtx,
            { color: "red", lineWidth: 3 }
          );
        }
      }
    });
  }

  componentWillUnmount() {
    Quagga.offDetected(this._onDetected);
  }

  _onDetected = (result) => {
    this.props.onDetected(result);
  };

  render() {
    return (
      <div id="interactive" title={this.props.deviceId} className="viewport" style={{position: 'relative'}}/>
    );
  }
}

export default Scanner;
