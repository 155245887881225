import React from 'react'
import axios from 'axios'
import {
  Breadcrumb,
  BreadcrumbItem,
  Button, Card,
  CardBody,
  CardHeader, Col,
  Form,
  FormGroup,
  Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader,
  Row,
  ListGroup, ListGroupItem,
  Table
} from "reactstrap";
import {confirmAlert} from "react-confirm-alert";
import jwt from "jsonwebtoken";
import Pagination from "react-js-pagination";
import PrintBillup from "./PrintBillup";
import ReactToPrint from "react-to-print";
import moment from 'moment';
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from 'react-select'
import {USER_ROLE} from "../../constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import s from "../packet/Packet.module.scss";
import ImageUploading from "react-images-uploading";
import {PhotoProvider, PhotoView} from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import fileDownload from "js-file-download";
let timerId = null;
class Billup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        total: 0,
        list: [],
        size: 20,
        from: 0,
        page: 1
      },
      modalPrintPackagesToggle: false,
      modalToggle: false,
      modalEditToggle: false,
      modalViewToggle: false,
      modalToggleUpdateBillup: false,
      modalBillupInfo: {},
      currUser: {},
      listCustomer: [],
      selectedCustomer: {
        name: '',
        khCode: '',
        phone: '',
        address: ''
      },
      createBillup: {
        customerName: "",
        customerId: "",
        customerAddress: "",
        customerPhone: "",
        note: "",
        paymentType: 'Tiền Mặt',
        statusType: "Chưa làm chứng từ",
        group: 'Hàng thường',
        localtion: 'HCM',
        images: ''
      },
      editBillup: {
        customerName: "",
        customerId: "",
        customerAddress: "",
        customerPhone: "",
        note: "",
        localtion: 'HCM'
      },
      isEdit: false,
      printId: '',
      printData: '',
      loading: false,
      date: new Date(),
      search: '',
      searchBy: 'billupId',
      fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1), // get last 30 days
      toDate: new Date(),
      images: [],
      imagesEdit: [],
      viewBillup: null,
      imagesView: [],
      err: '',
      localtion: ''
    }
    this.onDeleteBillup = this.onDeleteBillup.bind(this)
    this.openEditModal = this.openEditModal.bind(this)
    this.openViewModal = this.openViewModal.bind(this)
    this.onEditBillup = this.onEditBillup.bind(this)
    this.onCreateBillup = this.onCreateBillup.bind(this);
    this.onEditBillupChange = this.onEditBillupChange.bind(this);
    this.toggleUpdateBillup = this.toggleUpdateBillup.bind(this);
    this.onCreateBillupChange = this.onCreateBillupChange.bind(this);
    this.onSelectedCustomer = this.onSelectedCustomer.bind(this);
    this.onSelectedEditCustomer = this.onSelectedEditCustomer.bind(this);
    this.onClickPrintBillup = this.onClickPrintBillup.bind(this);
    this.printPackagesToggle = this.printPackagesToggle.bind(this);
    this.handleDatetoSelect = this.handleDatetoSelect.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.toggle = this.toggle.bind(this);
    this.searchBy = this.searchBy.bind(this);
    this.handleDatefromSelect = this.handleDatefromSelect.bind(this);
    this.handleDatetoSelect2 = this.handleDatetoSelect2.bind(this);
    this.onChange2 = this.onChange2.bind(this);
    this.setImages = this.setImages.bind(this);
  }

  async componentDidMount() {
    let token = localStorage.getItem('id_token')
    let currUser = jwt.decode(token)
    const urlParams = new URLSearchParams(window.location.search);
    const packingId = urlParams.get('packingId')
    if (packingId) {
      await this.getPackingInfo(packingId)
      this.toggle()
    }

    console.log(currUser)
    await this.setState({currUser, createBillup: {...this.state.createBillup, owner: currUser.displayName}})
    await this.getAllBillup()
    await this.getListCustomer()
  }

  getPackingInfo = async (packingId) => {
    try {
      const res = await axios.post(`/get-packing/${packingId}`)
      if (res && res.data) {
        const { data } = res
        this.setState({createBillup: {
            ...this.state.createBillup,
            ...data,
            packageId: data.id,
            images: [],
            imagesPacking: this.filterImages(data.images.split(','), data.imagesObj),
            id: undefined,
            note: '',
          },
        })
      }
    } catch (e) {
      console.error(e)
    }
  }
  filterImages(images, imagesObj) {
    if (!imagesObj) return images
    imagesObj = JSON.parse(imagesObj)
    // filter images width status Hoàn thành from imagesObj and imagesObj[key].images.includes(image)
    const data = images.filter(image => {
      for (const key in imagesObj) {
        if (key === 'Hoàn thành') {
          if (imagesObj[key].images.includes(image)) {
            return true
          }
        }
      }
    })
    console.log('filterImages', data, imagesObj)
    return data
  }

  async onCreateBillup(e) {
    e.preventDefault();
    try {
      if (this.state.loading) return
      const {createBillup, images} = this.state
      let message = '';
      if (!createBillup.customerId) {
        message = 'chọn khách hàng'
      } else if (!createBillup.customerName) {
        message = 'nhập tên người gửi'
      } else if (!createBillup.customerAddress) {
        message = 'nhập địa chỉ người gửi'
      } else if (!createBillup.customerPhone) {
        message = 'nhập số điện thoại người gửi'
      } else if (!createBillup.receiveName) {
        message = 'nhập tên người nhận'
      } else if (!createBillup.receiveAddress) {
        message = 'nhập địa chỉ người nhận'
      } else if (!createBillup.receivePhone) {
        message = 'nhập số điện thoại người nhận'
      } else if (!createBillup.packs) {
        message = 'nhập số kiện'
      } else if (!createBillup.weight) {
        message = 'nhập trọng lượng thực'
      } else if (!createBillup.weight2) {
        message = 'nhập trọng lượng quy đổi'
      } else if (!createBillup.service) {
        message = 'chọn dịch vụ'
      } else if (!createBillup.group) {
        message = 'chọn nhóm hàng'
      }
      if (message) {
        toast.error('Vui lòng ' + message)
        return
      }
      this.setState({loading: true})
      let imageLists = []
      if (images && images.length) {
        const formData = new FormData();
        images.forEach(i => {
          formData.append("photos", i.file);
        })
        const {data = []} = await axios({
          method: "post",
          url: "/upload-customer",
          data: formData,
          headers: {"Content-Type": "application/x-www-form-urlencoded"},
        });
        imageLists = data
        if (createBillup.imagesObj) {
          createBillup.imagesObj = JSON.parse(createBillup.imagesObj)
        }
        createBillup.imagesObj = createBillup.imagesObj ? {...createBillup.imagesObj, ...{ [createBillup.statusType]: {images: data, date: new Date(), type: 'billup'} } } :
          {[createBillup.statusType]: {images: data, date: new Date(), type: 'billup'}}
        createBillup.imagesObj = JSON.stringify(createBillup.imagesObj)
      }

      let {data: billupdata} = await axios.post('/create-billup', {...createBillup, date: this.state.date, images: imageLists.join(',')})
      this.setState((prevState) => ({modalToggle: !prevState.modalToggle, images: []}));
      await this.getAllBillup()

      // update report status
      let kgCode = billupdata?.id.toString()
      if (!kgCode.startsWith('92'))  {
        kgCode = 'TP' + kgCode
      }
      axios
        .put('/packages/' + kgCode, {
          reportStatus: createBillup.statusPayment,
        })
        .catch((error) => {
        })
      this.setState({loading: false})
      window.history.pushState({}, document.title, window.location.pathname);
    } catch (err) {
      toast.error('Đã có lỗi xảy ra ', err.error);
      this.setState({loading: false})
      return
    }
  }

  async onEditBillup(e) {
    try {
      const {editBillup, images, imagesEdit} = this.state
      let imageLists = []
      let message = '';
      if (!editBillup.customerId) {
        message = 'chọn khách hàng'
      } else if (!editBillup.customerName) {
        message = 'nhập tên người gửi'
      } else if (!editBillup.customerAddress) {
        message = 'nhập địa chỉ người gửi'
      } else if (!editBillup.customerPhone) {
        message = 'nhập số điện thoại người gửi'
      } else if (!editBillup.receiveName) {
        message = 'nhập tên người nhận'
      } else if (!editBillup.receiveAddress) {
        message = 'nhập địa chỉ người nhận'
      } else if (!editBillup.receivePhone) {
        message = 'nhập số điện thoại người nhận'
      } else if (!editBillup.packs) {
        message = 'nhập số kiện'
      } else if (!editBillup?.weight) {
        message = 'nhập trọng lượng thực'
      } else if (!editBillup?.weight2) {
        message = 'nhập trọng lượng quy đổi'
      } else if (!editBillup.service) {
        message = 'chọn dịch vụ'
      } else if (!editBillup.group) {
        message = 'chọn nhóm hàng'
      }
      if (message) {
        toast.error('Vui lòng ' + message)
        return
      }
      if (images && images.length) {
        const formData = new FormData();
        images.forEach(i => {
          formData.append("photos", i.file);
        })

        const {data = []} = await axios({
          method: "post",
          url: "/upload-customer",
          data: formData,
          headers: {"Content-Type": "application/x-www-form-urlencoded"},
        });
        imageLists = data
        if (editBillup.imagesObj) {
          editBillup.imagesObj = JSON.parse(editBillup.imagesObj)
        }
        editBillup.imagesObj = editBillup.imagesObj ? {...editBillup.imagesObj, ...{ [editBillup.statusType]: {images: data, date: new Date(), type: 'billup'} } } :
          {[editBillup.statusType]: {images: data, date: new Date(), type: 'billup'}}
        editBillup.imagesObj = JSON.stringify(editBillup.imagesObj)
      }


      if (imagesEdit && imagesEdit.length) {
        imageLists = [...imageLists, ...imagesEdit]
      }

      await axios.post(`/update-billup/${editBillup.id}`, {
        ...editBillup,
        date: this.state.date,
        images: imageLists.join(',')
      })
      this.setState((prevState) => ({
        modalEditToggle: !prevState.modalEditToggle,
        images: [],
        imagesEdit: [],
        err: ''
      }));
      await this.getAllBillup()
      // update report status


      let kgCode = this.showTPCode(editBillup.id)

      axios
        .put('/packages-by-kgCode/' + kgCode, {
          reportStatus: editBillup.statusPayment,
          totalPrice: editBillup.totalPrice
        })
        .catch((error) => {

        })

      e.preventDefault();
    } catch (err) {
      this.setState({err: err})
      toast.error('Đã có lỗi xảy ra');
      return
    }

  }

  openViewModal(obj) {
    let imagesView = obj.images
    if (imagesView) {
      imagesView = imagesView.split(',')
    }
    this.setState({viewBillup: obj, imagesView: imagesView})
    this.setState((prevState, props) => ({modalViewToggle: !prevState.modalViewToggle}));
  }

  toggle() {
    this.setState((prevState, props) => ({modalToggle: !prevState.modalToggle}));
  }

  openEditModal(obj) {
    let imagesEdit = obj.images
    if (imagesEdit) {
      imagesEdit = imagesEdit.split(',')
    }
    this.setState({editBillup: obj, imagesEdit: imagesEdit, date: moment(obj.date).subtract(7, 'h')})
    this.setState((prevState, props) => ({modalEditToggle: !prevState.modalEditToggle}));
  }

  toggleUpdateBillup() {

  }

  onCreateBillupChange(e) {
    const {name, value} = e.target;
    let createBillupInfo = this.state.createBillup
    createBillupInfo[name] = value
    this.setState({createBillup: createBillupInfo})
  }

  onEditBillupChange(e) {
    const {name, value} = e.target;
    let editBillupInfo = this.state.editBillup
    editBillupInfo[name] = value
    this.setState({editBillup: editBillupInfo})
  }

  formatNumber(num) {
    if (num) {
      return new Intl.NumberFormat().format(num)
    }
    return num
  }

  handleInput = (e) => {
    const {value} = e.target
    this.setState({search: value})
  }

  searchBy(val) {
    console.log(val)
    this.setState({searchBy: val})
  }

  searchByLocaltion(val) {
    console.log(val)
    this.setState({localtion: val})
  }

  onSearch = (e) => {
    this.getAllBillup({search: this.state.search})
  }

  async onDeleteBillup(id) {
    try {
      const self = this
      confirmAlert({
        title: "Delete Confirmation",
        message: "Are you sure to delete this Billup?",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              const res = await axios.get(`/delete-billup/${id}`)
              console.log(res)
              await self.getAllBillup()
            },
          },
          {
            label: "No",
            onClick: () => {
            },
          },
        ],
      });

    } catch (e) {
      console.error(e)
    }
  }

  onSelectChange = (e) => {
    const {value} = e
    this.setState({filterDateType: value})
  }
  onSelectedCustomer = (customer) => {
    console.log('change ', customer)
    if (customer) {
      const {id} = customer
      const temp = this.state.listCustomer.filter(e => e.id == id)?.[0]
      if (temp) {
        let createBillupInfo = this.state.createBillup
        createBillupInfo['customerName'] = temp.name
        createBillupInfo['customerId'] = temp.id
        createBillupInfo['customerAddress'] = temp.address
        createBillupInfo['customerPhone'] = temp.phone
        this.setState({selectedCustomer: temp, createBillup: createBillupInfo})

      }
    }
  }
  onSelectedEditCustomer = (customer) => {
    console.log('change ', customer)
    if (customer) {
      const {id} = customer
      const temp = this.state.listCustomer.filter(e => e.id == id)?.[0]
      if (temp) {
        let editBillupInfo = this.state.editBillup
        editBillupInfo['customerName'] = temp.name
        editBillupInfo['customerId'] = temp.id
        editBillupInfo['customerAddress'] = temp.address
        editBillupInfo['customerPhone'] = temp.phone
        this.setState({selectedCustomer: temp, editBillup: editBillupInfo})

      }
    }
  }
  handleInputChange(e) {
    const inputValue = e;
    // Clear any existing timer
    clearTimeout(timerId);
    // Set a new timer to delay the API call
    timerId = setTimeout(() => {
      console.log(inputValue);
      this.getListCustomer({ search: inputValue });
    }, 500);
  }

  async getAllBillup(params = {}) {
    try {
      this.setState({loading: true})
      const {searchBy = 'billupId', search = '', data} = this.state
      let res
      params.page = params.page  || data?.page
      let url = `/get-all-billup?page=${params.page || 1}&search=${search}&searchBy=${searchBy}`
      if (this.state.currUser.role === USER_ROLE.CONSULTANT || this.state.currUser.role === USER_ROLE.MANAGER || this.state.currUser.role === USER_ROLE.SALER || this.state.currUser.role === USER_ROLE.OPS) {
        url += `&owner=${this.state.currUser.displayName}`
      }
      url += `&fromDate=${this.state.fromDate}&toDate=${this.state.toDate}`
      if (this.state.localtion) {
        url += `&localtion=${this.state.localtion}`
      }
      if (this.state.currUser.role === USER_ROLE.MANAGER) {
        url += `&manager=${this.state.currUser.id}`
        url += `&owner_email=${this.state.currUser.email}`
      }
      res = await axios.get(url)
      if (res && res.data) {
        this.setState({data: res.data})
      }
      this.setState({loading: false})
    } catch (e) {
      console.error(e)
      this.setState({loading: false})
    }
  }

  async getListCustomer(params = { search: ''}) {
    try {
      let url = `/get-all-customer?page=${params.page || 1}&size=10&search=${params.search}`
      if (this.state.currUser.role === USER_ROLE.SALER || this.state.currUser.role === USER_ROLE.CONSULTANT
        || this.state.currUser.role === USER_ROLE.OPS || this.state.currUser.role === USER_ROLE.CUSTOMER) {
        url += `&owner=${this.state.currUser.displayName}`
      }
      const res = await axios.get(url,)
      if (res && res.data) {
        const list = res.data.list.map(i => {
          return {
            ...i,
            value: i.id,
            label: `KHTP${i.id} (${i.billupCount || 0})`
          }
        })
        this.setState({listCustomer: list || []})
      }
    } catch (e) {
      console.error(e)
    }
  }

  handlePageChange(pageNumber) {
    if (pageNumber === this.state.data.page) return
    this.getAllBillup({page: pageNumber})
  }

  printPackagesToggle() {
    this.setState((prevState) => ({
      modalPrintPackagesToggle: !prevState.modalPrintPackagesToggle,
    }));
  }

  onClickPrintBillup(obj) {
    this.setState({printData: obj});
    this.printPackagesToggle();
  }

  handleDatetoSelect(date) {
    console.log(date)
    this.setState({date})
  }

  checkPermission() {
    try {
      const role = this.state.currUser.role
      const email = this.state.currUser.email
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.SALER || role === USER_ROLE.MANAGER || role === USER_ROLE.OPS || role === USER_ROLE.MANAGER || role === USER_ROLE.CONSULTANT || role === USER_ROLE.ACCOUNTANT || email === 'customerservicetinphat@gmail.com') {
        return true
      }
      return false
    } catch (e) {
    }
  }

  checkPermission2(obj, isCreate) {
    try {
      const role = this.state.currUser.role
      const email = this.state.currUser.email
      const name = this.state.currUser.displayName
      if (!isCreate && obj && role === USER_ROLE.MANAGER && name === obj.owner) {
        return true
      }
      return role === USER_ROLE.ADMIN || role === USER_ROLE.SALER || role === USER_ROLE.MANAGER || role === USER_ROLE.OPS || role === USER_ROLE.CONSULTANT || role === USER_ROLE.ACCOUNTANT || role === USER_ROLE.DOCUMENT || email === 'customerservicetinphat@gmail.com';
    } catch (e) {
    }
  }

  checkPermissionSale(obj) {
    try {
      const role = this.state.currUser.role
      const email = this.state.currUser.email
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.MANAGER || role === USER_ROLE.ACCOUNTANT || role === USER_ROLE.SHIPPER || role === USER_ROLE.DOCUMENT || email === 'customerservicetinphat@gmail.com') {
        return true
      }

      if (role === USER_ROLE.SALER || role === USER_ROLE.CONSULTANT || role === USER_ROLE.OPS) {
        const createdAtDate = moment(obj.createdAt)
        const yesterday = moment().subtract(1, 'day')
        if (createdAtDate > yesterday) return true
      }
      return false
    } catch (e) {
    }
  }

  isAccountantOrAdmin() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ACCOUNTANT || role === USER_ROLE.ADMIN) {
        return true
      }
      return false
    } catch (e) {
    }
  }

  isNotSale() {
    try {
      const role = this.state.currUser.role
      if (role !== USER_ROLE.SALER) {
        return true
      }
      return false
    } catch (e) {
    }
  }

  isGiaoNhan() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.SHIPPER) {
        return true
      }
      return false
    } catch (e) {
    }
  }

  formatDate = (str) => {
    return moment(str).format('DD/MM/YY HH:mm')
  };

  handleDatefromSelect(date) {
    this.setState({fromDate: date});
  }

  handleDatetoSelect2(date) {
    console.log(date)
    this.setState({toDate: date});
  }

  getBillupImagesUrl(image, imagesObj) {
    let type = 'billup'
    if (imagesObj) {
      imagesObj = JSON.parse(imagesObj)
      for (const key in imagesObj) {
        if (imagesObj[key].images.includes(image)) {
          type = 'billup'
        }
      }
    }
    return `/customers/${image}`
  }


  onChange2 = (imageList, addUpdateIndex) => {
    // data for submit
    this.setImages(imageList);
  };

  setImages(imageList) {
    this.setState({images: imageList})
  }

  onImageEditRemove = (index) => {
    let {imagesEdit} = this.state
    const filteredItems = imagesEdit.filter(item => item !== imagesEdit[index])
    this.setState({imagesEdit: filteredItems})
  }

  async exportData() {
    await this.setState({disableDownloadButton: true})
    const {fromDate, toDate} = this.state
    let qs = `&datetype=createdAt&startDate=${fromDate}&endDate=${toDate}&searchBy=${this.state.searchBy}&search=${this.state.search}`
    if (this.state.currUser.role === USER_ROLE.SALER || this.state.currUser.role === USER_ROLE.CONSULTANT || this.state.currUser.role === USER_ROLE.OPS) {
      qs += `&owner=${this.state.currUser.displayName}`
    }
    if (this.state.localtion) {
      qs += `&localtion=${this.state.localtion}`
    }
    if (this.state.currUser.role === USER_ROLE.MANAGER) {
      qs += `&manager=${this.state.currUser.id}`
      qs += `&owner_email=${this.state.currUser.email}`
    }

    axios
      .get(`/download-billup?${qs}`, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(
          res.data,
          `BILLUP_DATA_${new Date().toJSON().slice(0, 10)}.xlsx`,
        )
        this.setState({disableDownloadButton: false})
      })
      .catch(() => {
        this.setState({disableDownloadButton: false})
      })
    return
  }

  showTPCode = (code) => {
    code = code?.toString()
    return code?.startsWith('92') ? code : 'TP' + code
  }

  render() {
    function ObjectRow(props) {
      const link = '/customers/' + props.obj
      return (
        <PhotoProvider key={props.key}>
          <PhotoView src={link}>
            <img src={link} alt=""/>
          </PhotoView>
        </PhotoProvider>
      );
    }

    const {data, editBillup, createBillup, viewBillup} = this.state
    const paymentTypeOption = {
      CASH: "Tiền mặt",
      BANK: "Chuyển khoản", BANK2: "Tiền mặt và Chuyển khoản",
    }
    const groupTypeOption = {
      CASH: "Hàng thường",
      BANK: "Hàng khó",
    }
    const statusTypeOption = {
      PROGRESS: "Chưa làm chứng từ",
      DONE: 'Đã làm chứng từ'
    }
    const statusPaymentOption = {
      NOTYET: "Chưa thanh toán",
      PAID: 'Đã thanh toán',
      DEBT: 'Công nợ'
    }
    const localtionOption = {
      HCM: "HCM",
      HN: 'HN',
      ĐN: 'ĐN'
    }

    const printBillup = () => {
      return `@media print {
        @page {
          size: A4 portrait;
          margin: 5mm;
          margin-top: 5mm;
        }  }
        `;
    };
    const maxNumber = 12;


    return (
      <div>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <Breadcrumb>
          <BreadcrumbItem>TRANG </BreadcrumbItem>
          <BreadcrumbItem active> Billup </BreadcrumbItem>
        </Breadcrumb>
        <div>
          <Row>
            <Col sm={6}>
              <div className="card-body">
                <Row>
                  <Col sm={6}>
                    <div style={{display: "flex", maxWidth: "200px"}}>
                      <InputGroup>
                        <label className='mr-sm-2' htmlFor='fromDate'>
                          From Date
                        </label>
                        <DatePicker
                          id='fromDate'
                          selected={this.state.fromDate}
                          onSelect={this.handleDatefromSelect}
                          className="form-control"
                        />
                      </InputGroup>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div style={{display: "flex", maxWidth: "200px"}}>
                      <InputGroup>
                        <label className='mr-sm-2' htmlFor='toDate'>
                          To Date
                        </label>
                        <DatePicker
                          id='toDate'
                          selected={this.state.toDate}
                          onSelect={this.handleDatetoSelect2}
                          className="form-control"
                        />
                      </InputGroup>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col sm={6} className='ml-auto'>
              <>
                <CardBody>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault()
                      this.onSearch()
                    }}
                  >
                    <div style={{marginBottom: '10px'}}>
                      <Label style={{marginRight: '8px'}}>Search By: </Label>
                      <select className="custom-select" style={{width: '200px', marginRight: '10px'}}
                              onChange={(e) => this.searchBy(e.target.value)}
                      >
                        <option value="billupId">Mã TP</option>
                        <option value="owner">Người tạo</option>
                        <option value="khId">Mã khách hàng</option>
                        <option value="receiveName">Người nhận</option>
                      </select>
                    </div>
                    <div style={{marginBottom: '10px'}}>
                      <Label style={{marginRight: '8px'}}>Khu Vực: </Label>
                      <select className="custom-select" style={{width: '200px', marginRight: '10px'}}
                              onChange={(e) => this.searchByLocaltion(e.target.value)}
                      >
                        <option value="">All</option>
                        <option value="HCM">HCM</option>
                        <option value="HN">HN</option>
                        <option value="ĐN">ĐN</option>
                      </select>
                    </div>
                    <InputGroup>
                      <Input
                        id="kgInput"
                        name="code"
                        value={this.state.search}
                        placeholder='Tìm kiếm ...'
                        onChange={this.handleInput}
                      />
                      <Button type="submit" className="btn-search ml-2">Search</Button>
                    </InputGroup>
                  </form>
                </CardBody>
              </>
            </Col>
          </Row>
        </div>
        <Card style={{marginTop: 20}}>
          <CardHeader className="d-flex justify-content-between align-items-center">
            <span>Danh sách Bill</span>
            {
              this.checkPermission2({}, true) && (
                <div style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
                  {data.list.length > 0 && (
                    <Button
                      disabled={this.state.disableDownloadButton}
                      onClick={() => this.exportData()}
                    >
                      Tải về Excel
                    </Button>
                  )}
                  {/*{this.checkPermission() && (*/}
                  {/*  <Button type="button" className="butt " onClick={this.toggle}>+ Tạo thông tin Bill</Button>*/}
                  {/*)}*/}
                </div>
              )
            }
          </CardHeader>

          <CardBody>
            {this.state.loading && (
              <div className="text-center">
                <span className="loader"></span>
              </div>
            )}
            <Table responsive bordered className="mb-0 table-hover">
              <thead>
              <tr>
                <th>#</th>
                <th>Mã TP</th>
                <th>Code</th>
                {/*<th>Thông tin người gửi</th>*/}
                <th>Account Tạo</th>
                <th>Thông tin người nhận</th>
                <th>Thông tin hàng</th>
                <th>Nội dung hàng hóa</th>
                {!this.isGiaoNhan() && (
                  <th>Số tiền</th>
                )}

                <th>Trạng Thái</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              {data.list &&
                data.list.map((obj, i) => (
                  <tr key={i + 1}>
                    <td>{i + 1}</td>
                    <td>
                      <a href="#" onClick={() => this.openViewModal(obj)}>
                        <strong>{this.showTPCode(obj.id)}</strong>
                      </a>
                    </td>
                    <td>
                      <div>Mã PK: <strong>{obj.pickupId}</strong></div>
                      <br/>
                      <div>Mã KH: <strong>KHTP{obj.customerId}</strong></div>
                    </td>
                    <td>
                      <div><strong>{obj.owner}</strong></div>
                      <div>{this.formatDate(obj.createdAt)}</div>
                    </td>
                    <td>
                      <p><strong>Receiver Name:</strong> {obj.receiveName}</p>
                      <p><strong>Receiver Phone:</strong> {obj.receivePhone}</p>
                      <p><strong>Receiver Address:</strong> {obj.receiveAddress}</p>
                    </td>
                    <td>
                      <div>
                        <p><strong>Số kiện:</strong> {obj.packs}</p>
                        <p><strong>Cân nặng:</strong> {obj.weight}kg</p>
                        <p><strong>Cân nặng quy đổi:</strong> {obj.weight2}kg</p>
                        <p><strong>Nhóm hàng:</strong> {obj.group || 'Hàng thường'}</p>
                        <p><strong>Dịch vụ:</strong> {obj.service}</p>
                        <p><strong>Hình thức:</strong> {obj.paymentType}</p>
                        {
                          obj.note && (
                            <p><strong>Note:</strong> {obj.note}</p>
                          )
                        }
                      </div>
                    </td>
                    <td className="text-center">{obj.info}</td>
                    {!this.isGiaoNhan() && (
                      <td className="text-center">{this.formatNumber(obj.totalPrice)}</td>
                    )}

                    <td className='text-center'>
                      <button
                        className={`btn-status text-upppercase ${obj.statusType === 'Đã làm chứng từ' ? "btn-success" : "btn-info"} ${
                          obj.statusType === 'Chưa làm chứng từ' ? "btn-danger" : ""
                        }`}>{obj.statusType}</button>
                      <br/>
                      <button
                        className={`mt-1 btn-status text-upppercase ${obj.statusPayment === 'Đã thanh toán' ? "btn-success" : "btn-info"} ${
                          obj.statusPayment === 'Chưa thanh toán' ? "btn-danger" : ""
                        }`}>{obj.statusPayment || 'Chưa thanh toán'}</button>
                    </td>
                    {this.checkPermissionSale(obj) ? (
                      <td>
                        <div style={{ minWidth: '140px'}}>
                          {!this.isGiaoNhan() && (
                            <Button type="button" color="info"
                                    onClick={(e) => this.onClickPrintBillup(obj)}><i className="fas fa-print"></i></Button>
                          )}
                          {
                            this.checkPermission2(obj) && (
                              <span>
                                {/*<Button type="button" color="info"*/}
                                {/*        onClick={(e) => this.openEditModal(obj)}><i className="fas fa-pen"></i></Button>*/}
                                <Button type="button" color="danger"
                                        onClick={(e) => this.onDeleteBillup(obj.id)}><i className="fas fa-trash"></i></Button>
                              </span>
                            )
                          }
                        </div>
                      </td>
                    ) : (

                      <td>
                        {!this.isGiaoNhan() && (
                          <button className='btn-status' onClick={(e) => this.onClickPrintBillup(obj)}><i className="fas fa-print"></i></button>
                        )}

                      </td>
                    )}

                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>

          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            activePage={data.page}
            itemsCountPerPage={data.size}
            totalItemsCount={data.total}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            hideFirstLastPages={true}
          />

        </Card>
        <Row>
          {/* Toggle Form Create Billup */}

          <Modal backdrop="static"
                 size="xl"
                 isOpen={this.state.modalToggle}
                 toggle={this.toggle}>
            <Form>
              <ModalHeader toggle={this.toggle}>Tạo thông tin hàng</ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="eventNumber">Khách hàng (*)</Label>
                      <Select
                        options={this.state.listCustomer}
                        value={this.state.selectedCustomer[0]?.id}
                        onChange={this.onSelectedCustomer}
                        onInputChange={this.handleInputChange}
                        isSearchable
                        autosize={false}/>
                    </FormGroup>
                    <FormGroup>
                      <Label for="name">Mã Khách Hàng (*)</Label>
                      <Input type="text" name="customerId" value={createBillup.customerId} id="customerName"
                             placeholder="" onChange={this.onCreateBillupChange}/>
                    </FormGroup><FormGroup>
                      <Label for="name">Tên người gửi (*)</Label>
                      <Input type="text" name="customerName" value={createBillup.customerName} id="customerName"
                             placeholder="Tên người gửi" onChange={this.onCreateBillupChange}/>
                    </FormGroup>
                    <FormGroup>
                      <Label for="content">Địa chỉ người gửi (*)</Label>
                      <Input type="text" name="customerAddress" value={createBillup.customerAddress} id="customerAddress"
                             placeholder="address" onChange={this.onCreateBillupChange}/>
                    </FormGroup>
                    <FormGroup>
                      <Label for="price">Số điện thoại người gửi (*)</Label>
                      <Input name="customerPhone" id="customerPhone" value={createBillup.customerPhone} placeholder="phone"
                             onChange={this.onCreateBillupChange}/>
                    </FormGroup>
                    <FormGroup>
                      <Label for="service">Dịch vụ (*)</Label>
                      <Input type="text" name="service" id="service"
                             value={createBillup.service}
                             placeholder="Dịch vụ"
                             onChange={this.onCreateBillupChange}/>
                    </FormGroup>
                    <FormGroup>
                      <Label for="name">Tên người nhận (*)</Label>
                      <Input type="text" name="receiveName" value={createBillup.receiveName} id="receiveName"
                             placeholder="Tên người nhận" onChange={this.onCreateBillupChange}/>
                    </FormGroup>
                    <FormGroup>
                      <Label for="content">Địa chỉ người nhận (*)</Label>
                      <Input type="text" name="receiveAddress" value={createBillup.receiveAddress} id="receiveAddress"
                             placeholder="Địa chỉ người nhận" onChange={this.onCreateBillupChange}/>
                    </FormGroup>
                    <FormGroup>
                      <Label for="price">Số điện thoại người nhận (*)</Label>
                      <Input name="receivePhone" id="receivePhone" value={createBillup.receivePhone}
                             placeholder="SĐT người nhận" onChange={this.onCreateBillupChange}/>
                    </FormGroup>
                    <FormGroup>
                      <Label for="moneyNumber">Số tiền bảo hiểm</Label>
                      <Input type="number" name="bhPrice" id="bhPrice" value={createBillup.bhPrice} placeholder="Số tiền bảo hiểm"
                             onChange={this.onCreateBillupChange}/>
                    </FormGroup>
                    {
                      this.state.currUser.role !== USER_ROLE.OPS && (
                        <div>
                          <FormGroup>
                            <Label for="moneyNumber">Số tiền thu khách</Label>
                            <Input type="number" name="totalPrice" id="totalPrice"
                                   value={createBillup.totalPrice}
                                   placeholder="Số tiền thu khách"
                                   onChange={this.onCreateBillupChange}/>
                          </FormGroup>
                          <FormGroup>
                            <Label for="paymentType">Hình thức thanh toán</Label>
                            <Input id="paymentType" name="paymentType"
                                   value={createBillup.paymentType}
                                   type="select" onChange={this.onCreateBillupChange}>
                              {Object.values(paymentTypeOption).map((object, i) => <option className="text-capitalize"
                                                                                           value={object}
                                                                                           key={i}>{object}</option>)}
                            </Input>
                          </FormGroup>
                        </div>
                      )
                    }

                    {
                      this.isAccountantOrAdmin() && (
                        <FormGroup>
                          <Label for="information">Tình trạng thanh toán</Label>
                          <Input id="statusPayment" name="statusPayment" type="select" onChange={this.onCreateBillupChange}>
                            {Object.values(statusPaymentOption).map((object, i) => <option className="text-capitalize"
                                                                                           value={object}
                                                                                           key={i}>{object}</option>)}
                          </Input>
                        </FormGroup>
                      )
                    }
                  </Col>
                  <Col md={6}>

                    <FormGroup>
                      <Label for="information">Nhập mã pickup</Label>
                      <Input type="text" name="pickupId"
                             value={createBillup.pickupId}
                             id="pickupId" placeholder="Mã pickup"
                             onChange={this.onCreateBillupChange}/>
                    </FormGroup>
                    <FormGroup>
                      <Label for="typeGood">Nhóm hàng</Label>
                      <Input id="group" name="group"
                             value={createBillup.group}
                             type="select" onChange={this.onCreateBillupChange}>
                        {Object.values(groupTypeOption).map((object, i) => <option className="text-capitalize"
                                                                                   value={object}
                                                                                   key={i}>{object}</option>)}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="weight">Trọng lượng thực (kg) (*)</Label>
                      <Input type="number"
                             value={createBillup.weight}
                             name="weight" id="weight" placeholder="Trọng lượng thực"
                             onChange={this.onCreateBillupChange}/>
                    </FormGroup>
                    <FormGroup>
                      <Label for="weight">Trọng lượng quy đổi (kg) (*)</Label>
                      <Input type="number"
                             value={createBillup.weight2}
                             name="weight2" id="weight2" placeholder="Trọng lượng quy đổi"
                             onChange={this.onCreateBillupChange}/>
                    </FormGroup>
                    <FormGroup>
                      <Label for="eventNumber">Số kiện (*)</Label>
                      <Input type="number"
                             value={createBillup.packs}
                             name="packs" id="packs" placeholder="Số kiện"
                             onChange={this.onCreateBillupChange}/>
                    </FormGroup>
                    <FormGroup>
                      <Label for="information">Thông tin hàng ngắn gọn (In bill)</Label>
                      <input
                        className="form-control"
                        id="infoShort"
                        value={createBillup.infoShort}
                        placeholder="Thông tin hàng short"
                        onChange={this.onCreateBillupChange}
                        name="infoShort"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="information">Thông tin hàng</Label>
                      <textarea
                        className="form-control"
                        rows="4"
                        value={createBillup.info}
                        id="info"
                        placeholder="Thông tin hàng"
                        onChange={this.onCreateBillupChange}
                        name="info"
                      />
                    </FormGroup>
                    {
                      this.isNotSale() && (
                        <FormGroup>
                          <Label for="statusType">Trạng thái</Label>
                          <Input id="statusType" name="statusType" type="select" onChange={this.onCreateBillupChange}>
                            {Object.values(statusTypeOption).map((object, i) => <option className="text-capitalize"
                                                                                        value={object}
                                                                                        key={i}>{object}</option>)}
                          </Input>
                        </FormGroup>
                      )
                    }
                    <FormGroup>
                      <Label for="localtion">Khu vực</Label>
                      <Input id="localtion" name="localtion"
                             value={createBillup.localtion}
                             type="select" onChange={this.onCreateBillupChange}>
                        {Object.values(localtionOption).map((object, i) => <option className="text-capitalize"
                                                                                   value={object}
                                                                                   key={i}>{object}</option>)}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="note">Ghi chú</Label>
                      <textarea
                        className="form-control"
                        rows="4"
                        id="note"
                        value={createBillup.note}
                        placeholder="Ghi chú"
                        onChange={this.onCreateBillupChange}
                        name="note"
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="price">Hình ảnh</Label>
                      {
                        (createBillup.imagesPacking && createBillup.imagesPacking.length) ? (
                          <div className="image-list" style={{display: 'flex', flexWrap: 'wrap', gridGap: '15px'}}>
                            {createBillup.imagesPacking?.map((image, index) => (
                              <div key={index} className="image-item" style={{marginTop: '10px', position: 'relative'}}>
                                <img src={`/pickup/${image}`} alt="" width="100"/>
                              </div>
                            ))}
                          </div>
                        ) : ''
                      }
                      <ImageUploading
                        multiple
                        value={this.state.images}
                        onChange={this.onChange2}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                      >
                        {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                          }) => (
                          // write your building UI
                          <div className="upload__image-wrapper">
                            <a className="btn btn-primary"
                               style={isDragging ? {color: 'red'} : undefined}
                               onClick={onImageUpload}
                               {...dragProps}
                            >
                              Chọn hình ảnh
                            </a>
                            &nbsp;&nbsp;&nbsp;
                            {
                              (this.state.images && this.state.images.length) ? (
                                <a onClick={onImageRemoveAll} className="btn btn-danger">Remove all images</a>
                              ) : ''
                            }
                            <div className="image-list" style={{display: 'flex', flexWrap: 'wrap', gridGap: '15px'}}>
                              {this.state.images.map((image, index) => (
                                <div key={index} className="image-item" style={{marginTop: '10px'}}>
                                  <img src={image['data_url']} alt="" width="100"/>
                                  <div className="image-item__btn-wrapper" style={{marginTop: '8px'}}>
                                    <a onClick={() => onImageRemove(index)}>
                                      <img src="/close.png" alt="" width="10"/>
                                    </a>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </ImageUploading>
                    </FormGroup>

                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                {this.state.loading && (
                  <div className="text-center">
                    <span className="loader"></span>
                  </div>
                )}
                <Button type="submit" color="primary" onClick={(e) => this.onCreateBillup(e)}>Tạo mới</Button>{' '}
                <Button color="secondary" onClick={this.toggle}>Hủy</Button>
              </ModalFooter>
            </Form>
          </Modal>

          {/* Toggle Form Edit Billup */}
          <Modal
            backdrop="static"
            size="xl"
            isOpen={this.state.modalEditToggle} toggle={this.openEditModal}>
            <Form>
              <ModalHeader toggle={this.openEditModal}>Edit Bill</ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={6}>
                    {
                      this.checkPermission2() && (
                        <div>
                          <FormGroup>
                            <Label for="eventNumber">Khách hàng</Label>

                            <Select
                              options={this.state.listCustomer}
                              value={this.state.selectedCustomer[0]?.id}
                              onChange={this.onSelectedEditCustomer}
                              autosize={false}/>
                          </FormGroup>
                          <FormGroup>
                            <Label for="name">Mã khách hàng</Label>
                            <Input type="text" name="customerId" value={editBillup.customerId} id="customerId"
                                   placeholder="Mã khách hàng" onChange={this.onEditBillupChange}/>
                          </FormGroup>
                          <FormGroup>
                            <Label for="name">Tên Khách Hàng</Label>
                            <Input type="text" name="customerName" value={editBillup.customerName} id="customerName"
                                   placeholder="Tên khách hàng" onChange={this.onEditBillupChange}/>
                          </FormGroup>
                          <FormGroup>
                            <Label for="content">Địa chỉ</Label>
                            <Input type="text" name="customerAddress" value={editBillup.customerAddress} id="customerAddress"
                                   placeholder="address" onChange={this.onEditBillupChange}/>
                          </FormGroup>
                          <FormGroup>
                            <Label for="price">Phone</Label>
                            <Input name="customerPhone" id="customerPhone" value={editBillup.customerPhone}
                                   placeholder="phone" onChange={this.onEditBillupChange}/>
                          </FormGroup>
                          <FormGroup>
                            <Label for="service">Dịch vụ</Label>
                            <Input type="text" name="service" id="service" value={editBillup.service} placeholder="Dịch vụ"
                                   onChange={this.onEditBillupChange}/>
                          </FormGroup>
                          <FormGroup>
                            <Label for="name">Tên người nhận</Label>
                            <Input type="text" name="receiveName" value={editBillup.receiveName} id="receiveName"
                                   placeholder="Tên người nhận" onChange={this.onEditBillupChange}/>
                          </FormGroup>
                          <FormGroup>
                            <Label for="content">Địa chỉ người nhận</Label>
                            <Input type="text" name="receiveAddress" value={editBillup.receiveAddress} id="receiveAddress"
                                   placeholder="Địa chỉ người nhận" onChange={this.onEditBillupChange}/>
                          </FormGroup>
                          <FormGroup>
                            <Label for="price">Số điện thoại người nhận</Label>
                            <Input name="receivePhone" id="receivePhone" value={editBillup.receivePhone}
                                   placeholder="SĐT người nhận" onChange={this.onEditBillupChange}/>
                          </FormGroup>
                          <FormGroup>
                            <Label for="typeGood">Mã pickup</Label>
                            <Input type="text" name="pickupId" id="pickupId" value={editBillup.pickupId}
                                   placeholder="Mã kiện hàng" onChange={this.onEditBillupChange}/>
                          </FormGroup>
                          <FormGroup>
                            <Label for="eventNumber">Số kiện</Label>
                            <Input type="number" name="packs" id="packs" value={editBillup.packs} placeholder="Số kiện"
                                   onChange={this.onEditBillupChange}/>
                          </FormGroup>
                          <FormGroup>
                            <Label for="weight">Trọng lượng thực</Label>
                            <Input type="number" name="weight" id="weight" value={editBillup?.weight}
                                   placeholder="Trọng lượng thực" onChange={this.onEditBillupChange}/>
                          </FormGroup>
                          <FormGroup>
                            <Label for="weight">Trọng lượng quy đổi</Label>
                            <Input type="number" name="weight2" id="weight2" value={editBillup?.weight2}
                                   placeholder="Trọng lượng quy đổi" onChange={this.onEditBillupChange}/>
                          </FormGroup>
                          <FormGroup>
                            <Label for="information">Thông tin hàng ngắn gọn (In bill)</Label>
                            <input
                              className="form-control"
                              id="infoShort"
                              value={editBillup.infoShort}
                              placeholder="Thông tin hàng short"
                              onChange={this.onEditBillupChange}
                              name="infoShort"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="information">Thông tin hàng</Label>
                            <textarea
                              className="form-control"
                              rows="4"
                              id="info"
                              value={editBillup.info}
                              placeholder="Thông tin hàng"
                              onChange={this.onEditBillupChange}
                              name="info"
                            />
                          </FormGroup>
                        </div>
                      )
                    }
                  </Col>
                  <Col md={6}>
                    {
                      this.checkPermission2() && (
                        <div>
                          <FormGroup>
                            <Label for="typeGood">Nhóm hàng</Label>
                            <Input id="group" name="group" type="select" value={editBillup.group}
                                   onChange={this.onEditBillupChange}>
                              {Object.values(groupTypeOption).map((object, i) => <option className="text-capitalize"
                                                                                         value={object}
                                                                                         key={i}>{object}</option>)}
                            </Input>
                          </FormGroup>
                          <FormGroup>
                            <Label for="localtion">Khu vực</Label>
                            <Input id="localtion" name="localtion" value={editBillup.localtion} type="select"
                                   onChange={this.onEditBillupChange}>
                              {Object.values(localtionOption).map((object, i) => <option className="text-capitalize"
                                                                                         value={object}
                                                                                         key={i}>{object}</option>)}
                            </Input>
                          </FormGroup>
                          <FormGroup>
                            <Label for="moneyNumber">Số tiền bảo hiểm</Label>
                            <Input type="number" name="bhPrice" id="bhPrice" value={editBillup.bhPrice}
                                   placeholder="Số tiền bảo hiểm" onChange={this.onEditBillupChange}/>
                          </FormGroup>
                        </div>
                      )
                    }
                    {
                      this.state.currUser.role !== USER_ROLE.OPS && (
                        <div>
                          <FormGroup>
                            <Label for="moneyNumber">Số tiền thu khách</Label>
                            <Input type="number" name="totalPrice" id="totalPrice" value={editBillup.totalPrice}
                                   placeholder="Số tiền thu khách" onChange={this.onEditBillupChange}/>
                          </FormGroup>
                          <FormGroup>
                            <Label for="paymentType">Hình thức thanh toán</Label>
                            <Input id="paymentType" name="paymentType" value={editBillup.paymentType} type="select"
                                   onChange={this.onEditBillupChange}>
                              {Object.values(paymentTypeOption).map((object, i) => <option className="text-capitalize"
                                                                                           value={object}
                                                                                           key={i}>{object}</option>)}
                            </Input>
                          </FormGroup>
                        </div>
                      )
                    }
                    {
                      this.isAccountantOrAdmin() && (
                        <FormGroup>
                          <Label for="statusPayment">Tình trạng thanh toán</Label>
                          <Input id="statusPayment" name="statusPayment" type="select" value={editBillup.statusPayment}
                                 onChange={this.onEditBillupChange}>
                            {Object.values(statusPaymentOption).map((object, i) => <option className="text-capitalize"
                                                                                           value={object}
                                                                                           key={i}>{object}</option>)}
                          </Input>
                        </FormGroup>
                      )
                    }
                    <FormGroup>
                      <Label for="statusType">Trạng thái</Label>
                      <Input id="statusType" name="statusType" type="select" value={editBillup.statusType}
                             onChange={this.onEditBillupChange}>
                        {Object.values(statusTypeOption).map((object, i) => <option className="text-capitalize"
                                                                                    value={object}
                                                                                    key={i}>{object}</option>)}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="note">Ghi chú</Label>
                      <textarea
                        className="form-control"
                        rows="4"
                        id="note"
                        value={editBillup.note}
                        placeholder="Ghi chú"
                        onChange={this.onEditBillupChange}
                        name="note"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="price">Hình ảnh</Label>
                      {
                        (this.state.imagesEdit && this.state.imagesEdit.length) ? (
                          <div className="image-list" style={{display: 'flex', flexWrap: 'wrap', gridGap: '15px'}}>
                            {this.state.imagesEdit.map((image, index) => (
                              <div key={index} className="image-item" style={{marginTop: '10px', position: 'relative'}}>
                                <a href={`/customers/${image}`} target="_blank"><img src={`/customers/${image}`} alt="" width="100"/></a>
                                <a href={`/pickup/${image}`} target="_blank"><img src={`/pickup/${image}`} alt="" width="100"/></a>
                                <div className="image-item__btn-wrapper" style={{
                                  marginTop: '8px', position: 'absolute', right: 0,
                                  top: 0
                                }}>
                                  <a onClick={() => this.onImageEditRemove(index)}>
                                    <img src="/close.png" alt="" width="10"/>
                                  </a>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : ''
                      }


                      <ImageUploading
                        multiple
                        value={this.state.images}
                        onChange={this.onChange2}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                      >
                        {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                          }) => (
                          // write your building UI
                          <div className="upload__image-wrapper"
                               style={{marginTop: '30px'}}>
                            <a className="btn btn-primary"
                               style={isDragging ? {color: 'red'} : undefined}
                               onClick={onImageUpload}
                               {...dragProps}
                            >
                              Chọn thêm hình ảnh
                            </a>
                            &nbsp;&nbsp;&nbsp;
                            {
                              (this.state.images && this.state.images.length) ? (
                                <a onClick={onImageRemoveAll} className="btn btn-danger">Remove all images</a>
                              ) : ''
                            }
                            <div className="image-list" style={{display: 'flex', flexWrap: 'wrap', gridGap: '15px'}}>
                              {this.state.images.map((image, index) => (
                                <div key={index} className="image-item" style={{marginTop: '10px'}}>
                                  <img src={image['data_url']} alt="" width="100"/>
                                  <div className="image-item__btn-wrapper" style={{marginTop: '8px'}}>
                                    <a onClick={() => onImageRemove(index)}>
                                      <img src="/close.png" alt="" width="10"/>
                                    </a>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </ImageUploading>
                    </FormGroup>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={(e) => this.onEditBillup(e)}>Update</Button>{' '}
                <Button color="secondary" onClick={this.openEditModal}>Cancel</Button>
              </ModalFooter>
            </Form>
          </Modal>

          {/* Toggle Form View Billup */}
          <Modal isOpen={this.state.modalViewToggle} toggle={this.openViewModal}>
            {viewBillup && (
              <Form>
                <ModalHeader toggle={this.openViewModal}>Bill Info: {this.showTPCode(viewBillup.id)}</ModalHeader>
                <ModalBody>
                  <ListGroup>
                    <ListGroupItem>Mã pickup: {viewBillup.pickupId} </ListGroupItem>
                    <ListGroupItem>Mã KH: KHTP{viewBillup.customerId}</ListGroupItem>
                    <ListGroupItem>Account Tạo: {viewBillup.owner} </ListGroupItem>
                    <ListGroupItem>Thông tin người nhận:
                      <p><strong>Receiver Name:</strong> {viewBillup.receiveName}</p>
                      <p><strong>Receiver Phone:</strong> {viewBillup.receivePhone}</p>
                      <p><strong>Receiver Address:</strong> {viewBillup.receiveAddress}</p>
                    </ListGroupItem>
                    <ListGroupItem>Thông tin hàng:

                      <div>
                        <p><strong>Số kiện:</strong> {viewBillup.packs}</p>
                        <p><strong>Cân nặng:</strong> {viewBillup.weight}kg</p>
                        <p><strong>Cân nặng quy đổi:</strong> {viewBillup.weight2}kg</p>
                        <p><strong>Nhóm hàng:</strong> {viewBillup.group || 'Hàng thường'}</p>
                        <p><strong>Dịch vụ:</strong> {viewBillup.service}</p>
                        <p><strong>Hình thức:</strong> {viewBillup.paymentType}</p>
                        {
                          viewBillup.note && (
                            <p><strong>Note:</strong> {viewBillup.note}</p>
                          )
                        }
                      </div>
                    </ListGroupItem>
                    <ListGroupItem>Nội dung hàng hóa: {viewBillup.info}</ListGroupItem>
                    {
                      !this.isGiaoNhan() && (
                        <ListGroupItem>Số tiền: {this.formatNumber(viewBillup.totalPrice)}</ListGroupItem>
                      )
                    }
                    <ListGroupItem>Ngày Tạo: {this.formatDate(viewBillup.createdAt)}</ListGroupItem>
                    <ListGroupItem>Trạng Thái: {viewBillup.statusType}</ListGroupItem>
                    <ListGroupItem>Tình trạng thanh
                      toán: {viewBillup.statusPayment || 'Chưa thanh toán'}</ListGroupItem>
                    {(this.state.imagesView && this.state.imagesView.length) ? this.state.imagesView.map((object, i) =>
                      <ObjectRow obj={object} key={i}/>
                    ) : ''}

                  </ListGroup>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={this.openViewModal}>Close</Button>
                </ModalFooter>
              </Form>
            )}

          </Modal>

          <Modal
            isOpen={this.state.modalPrintPackagesToggle}
            scrollable
            centered
            size='lg'
            fullscreen="true |xxl-down'"
            toggle={this.printPackagesToggle}
          >
            <ModalHeader toggle={this.printPackagesToggle}>
              Print Billup
            </ModalHeader>
            <ModalBody>
              <style> {printBillup()} </style>
              <div>
                <PrintBillup
                  billData={this.state.printData}
                  ref={(el) => (this.componentRef = el)}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <ReactToPrint
                trigger={() => {
                  return (
                    <Button href='#'> Print this out!</Button>
                  );
                }}
                content={() => this.componentRef}
              />
            </ModalFooter>
          </Modal>

        </Row>
      </div>
    )
  }
}

export default Billup
