import React from 'react'
import axios from 'axios'
import {
  Breadcrumb,
  BreadcrumbItem, Button,
  Card,
  CardBody,
  CardHeader, Col, Form, FormGroup, Input, Label, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader,
  Row, Table,
} from "reactstrap";
import {Link} from "react-router-dom";
import jwt from "jsonwebtoken";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {USER_ROLE} from "../../constants";
import BillToPrint from "./BillToPrint";
import ReactToPrint from "react-to-print";
import InvoiceToPrint from "./InvoiceToPrint";
import PrintPackages from "./PrintPackages";
import DatePicker from "react-datepicker";
import PickupListTable from "./pickupListTable/PickupListTable";
import s from "./Packet.module.scss";
import PrintSMOPS from "./PrintSMOPS";
import PrintBillup from "./PrintBillup";



class PackageDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
      currUser: {},
      packList: [],
      service: {},
      packInvoiceList: [],
      packageInfo: {},
      printSMData: {},
      modalSMToggle: false,
    }
    this.printToggle = this.printToggle.bind(this);
    this.printPackagesToggle = this.printPackagesToggle.bind(this);
    this.onClickPrint = this.onClickPrint.bind(this);
    this.onClickPrintInv = this.onClickPrintInv.bind(this);
    this.onClickPrintPackages = this.onClickPrintPackages.bind(this);
    this.printSMToggle = this.printSMToggle.bind(this);
    this.printInvToggle = this.printInvToggle.bind(this);
  }

  async componentDidMount() {
    let { id } = this.props.match.params;
    let token = localStorage.getItem('id_token');
    let currUser = jwt.decode(token);
    this.setState({ currUser: currUser });

    await this.getPackageDetail(id)
  }
  jsonEscape(str)  {
    return str.replace(/\n/g, ' ');
  }

  getPackageInfo = async (id) => {
    try {
      const data = await axios.get(`/get-packageinfo/${id}`)
      if (data) {
        this.setState({ packageInfo: data.data })
      }
    } catch (e) {
      return {}
    }
  }

  getPackageDetail = async (packageId) => {
    try {
      const { data } = await axios.get(`/get-package/${packageId}`)
      if (data) {
        console.log(data.packs)
        this.setState({ data: data, packList: JSON.parse(data?.packs || ''), packInvoiceList: JSON.parse(this.jsonEscape(data.invoices))})
        if (data.logisticService) {
          await this.getServiceByName(data.logisticService)
        }
        if (data.kgCode) {
          await this.getPackageInfo(data.kgCode)
        }
      }
    } catch (e) {
      console.log(e)
    }
  }
  getServiceByName = async (name) => {
    try {
      const { data } = await axios.get(`/get-service-by-name/${name}`)
      if (data) {
        this.setState({ service: data })
      }
    } catch (e) {
      console.log(e)
    }
  }


  onClickPrint(e, pgk) {
    this.setState({ printData: pgk });
    this.printToggle();
  }

  printToggle() {
    this.setState((prevState) => ({ modalToggle: !prevState.modalToggle }));
  }

  printPackagesToggle() {
    this.setState((prevState) => ({
      modalPrintPackagesToggle: !prevState.modalPrintPackagesToggle,
    }));
  }

  onClickPrintInv(e, pgk) {
    this.setState({ printInvData: pgk });
    this.printInvToggle();
  }

  async onClickPrintPackages(e, pgk) {
    try {
      const {data: packageInfo} = await axios.get(`/get-packageinfo/${pgk.kgCode}`)
      const {data: customer} = await axios.get(`/get-customer/${packageInfo?.customerId}`)
      pgk.packageInfo = packageInfo
      pgk.customer = customer
    } catch (e) {
      console.log(e)
    }
    this.setState({ printInvData: pgk });
    this.printPackagesToggle();
  }

  printInvToggle() {
    this.setState((prevState) => ({
      modalInvToggle: !prevState.modalInvToggle,
    }));
  }

  async onClickPrintSM(e, pgk) {
    const {data: user} = await axios.get(`/user-by-name/${pgk.owner}`)
    this.setState({printSMData: pgk, pkRole: user?.role});
    this.printSMToggle();
  }

  printSMToggle() {
    this.setState((prevState) => ({
      modalSMToggle: !prevState.modalSMToggle,
    }));
  }

  formatNumber(num) {
    if (num) {
      return new Intl.NumberFormat().format(num)
    }
    return num
  }
  checkPermission() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.SALER || role === USER_ROLE.CONSULTANT || role === USER_ROLE.MANAGER || role === USER_ROLE.OPS || role === USER_ROLE.SHIPPER) {
        return true
      }
      return false
    } catch (e) {
    }
  }
  checkPermission2() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.SALER || role === USER_ROLE.CONSULTANT || role === USER_ROLE.MANAGER || role === USER_ROLE.OPS) {
        return true
      }
      return false
    } catch (e) {
    }
  }

  getConvertedWeight(pack) {
    // get listService from local storage
    const { data } = this.state
    const { service } = this.state
    if (service.convertNumber) {
      return `${pack.length}L x ${pack.width}W x ${pack.height}H / ${service.convertNumber} = ${pack.convertRatio}`
    }
    return `${pack.length}L x ${pack.width}W x ${pack.height}H / 5000 = ${pack.convertRatio}`

  }

  getHawbCode = (x, index) => {
    try {
      const {data: pkg} = this.state
      let hawbCode = ``
      if (pkg.hawbCode) {
        let hawbArr = pkg.hawbCode.trim().split(',')
        if (hawbArr.length > index) {
          hawbCode = hawbArr[index]
        }
      }
      return hawbCode
    } catch (e) {
      return ''
    }
  }
  getTrackingCode = (x, index) => {
      try {
        const {data: pkg} = this.state
        let trackingCode = ``
        if (pkg.logisticCode) {
          let hawbArr = pkg.logisticCode.trim().split(',')
          if (hawbArr.length > index) {
            trackingCode = hawbArr[index]
          }
        }
        return trackingCode
      } catch (e) {
        return ''
      }
    }


  render() {
    const printBill = () => {
      return `@media print {
        @page { 
          size: A4 portrait;
          margin: 5mm;
        }  }
        
        table, tbody,td,tr {  
         border: 1px solid black ;
         padding-left: 10px; 
         font-familly: "Times New Roman"; 
        }
      
      `;
    };

    const printInvPage = () => {
      return `@media print {
        @page {
          size: A4 portrait;
          margin: 5mm;
          margin-top: 20mm;
        }  }
         
        table, tbody,th,td,tr {  
          border: 1px solid black;
          font-familly: "Times New Roman"; 
          font-size="40px";
         }
        `;
    };

    const printPackages = () => {
      return `@media print {
        @page {
          size: A4 portrait;
          margin: 5mm;
          margin-top: 5mm;
        }  }
         
        table, tbody,th,td,tr {  
          border: 1px solid black;
          font-familly: "Times New Roman"; 
          font-size="40px";
         }
        `;
    };

    const { data, packageInfo } = this.state

    return (
      <div>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <Breadcrumb>
          <BreadcrumbItem>TRANG </BreadcrumbItem>
          <BreadcrumbItem active>Package Detail</BreadcrumbItem>
        </Breadcrumb>
        <div className="row package-detail">
          <div className="col-md-3">
            <div className="card card-primary">
              <div className="card-header">
                <div className="card-title">Thông tin Lô Hàng : <b>{data.kgCode}</b>
                  <Link to={`/app/bill/${data.kgCode}`} className="text-black-50"> <i className="fas fa-edit"></i></Link></div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="callout callout-info">
                      <h5>Thông tin người gửi</h5>
                      <p>- Công ty: <b>{ data.senderCompanyName }</b></p>
                      <p>- Tên người gửi: <b> { data.senderName }</b></p>
                      <p>- Số Điện Thoại: {data.senderPhone}</p>
                      <p>- Email: {data.senderEmail}</p>
                    </div>
                    <div className="callout callout-info">
                      <h5>Thông tin dịch vụ</h5>
                      <p>- Dịch vụ: <b>{data.logisticService}</b></p>
                      <p>- Brand: {data.location}</p>
                      <p>- Owner: {data?.owner}</p>
                      {
                        this.state.currUser.role !== USER_ROLE.OPS && (
                          <div>
                            <p>- Nhóm hàng: {packageInfo?.group || 'Hàng thường'}</p>
                            <p>- Số tiền thu khách: {data?.totalPrice || '0'}</p>
                            <p>- Số tiền bảo hiểm: {data?.bhPrice || '0'}</p>
                            <p>- Hình Thức Thanh Toán: {packageInfo?.paymentType || ''}</p>
                          </div>
                        )
                      }
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="callout callout-info">
                      <h5>Thông tin người nhận</h5>
                      <p>- Công ty: <b>{data.receiverCompanyName}</b></p>
                      <p>- Tên người nhận: <b>{data.receiverName}</b></p>
                      <p>- Số Điện Thoại: {data.receiverPhone}</p>
                      <p>- Địa chỉ: {data.receiverAddr1}</p>
                      <p>- State: <b>{data.receiverCity}</b></p>
                      <p>- Post Code <b>: {data.receiverPostalCode}</b></p>
                      <p>- Quốc Gia <b>: {data.receiverCountry}</b></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="card card-primary">
              <div className="card-header">
                <div className="card-title">Thông tin kiện hàng</div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex flex-wrap package-detail-action">
                      <button type="button" className="btn btn-primary " onClick={(e) => this.onClickPrint(e, data)}><i
                        className="fa fa-print"></i>Print Bill
                      </button>
                      <button type="button" className="btn btn-primary " onClick={(e) => this.onClickPrintInv(e, data)}><i
                        className="fa fa-print"></i>Print Label
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => this.onClickPrintSM(e, data)}
                      >
                        <i className="fa fa-print"/>Print Shipping Mark
                      </button>
                      {
                        (this.state.currUser.role !== USER_ROLE.OPS) && (
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => this.onClickPrintPackages(e, data)}
                          >
                            <i className="fa fa-print"/>Billup
                          </button>
                        )
                      }
                    </div>
                  </div>

                </div>
                <div className="row tp-table">
                  <div className="col-md-12">
                    <hr />

                    <Table
                      responsive
                      className="table-bordered table-sm"
                      width="100%"
                    >
                      <thead className="text-uppercase table-light">
                      <tr className="text-left">
                        <th>HAWB Code</th>
                        <th>Tracking</th>
                        <th>Quantity</th>
                        <th>Type</th>
                        <th>Weight (kg)</th>
                        <th>Converted Weight</th>
                        <th>Charged Weight</th>
                        <th>Img</th>
                      </tr>
                      </thead>
                      <tbody>
                      {this.state.packList && this.state.packList.map((x, i) => {
                        return (
                          <tr>
                            <td className="text-center">
                              {/*<a href={`https://tracking.gopost.vn?tpCode=${this.getHawbCode(x, i)}`} target="_blank">{this.getHawbCode(x, i)}</a>*/}
                              <a href={`https://tracking.gopost.vn?tpCode=${data.kgCode}`} target="_blank">{this.getHawbCode(x, i)}</a>

                            </td>
                            <td className="text-center">{this.getTrackingCode(x, i)}</td>
                            <td className="text-center">{x.quantity || data.docCount}</td>
                            <td className="text-capitalize text-center">{x.type || 'doc'}</td>
                            <td className="text-center">{x.weight || data.docWeight}kg</td>
                            <td className="text-center">{this.getConvertedWeight(x)}</td>
                            <td className="text-center">{x.chargedWeight || data.docWeight} kg</td>
                            <td className="text-center">
                              <a href={`/pickup/${x.images}`} target="_blank"> <img src={`/pickup/${x.images}`} alt="" width="100"/></a>
                            </td>
                          </tr>
                        )
                      })}
                      </tbody>
                    </Table>

                    <hr />

                    <Table responsive className="table-bordered table-sm mb-0">
                      <thead className="text-uppercase table-light">
                      <tr>
                        <th className="text-center">
                          Goods Details
                          <br />
                          (Product names, materials, stamps, ...)
                        </th>
                        <th className="text-center">Quantity</th>
                        <th className="text-center">Unit</th>
                        <th className="text-center">Price</th>
                        <th className="text-center">Total Value</th>
                      </tr>
                      </thead>
                      <tbody>
                      {this.state.packInvoiceList.length ? this.state.packInvoiceList.map((x, i) => {
                        return (
                          <tr>
                            <td className="text-center">
                              {x.description}
                            </td>
                            <td className="text-center">
                              {x.quantity}
                            </td>
                            <td>
                              {x.unit}
                            </td>
                            <td className="text-center">
                              {x.unitPrice}
                            </td>
                            <td name="subTotal" className="text-center">
                              {parseFloat(x.subTotal || 0).toFixed(1)}
                            </td>
                          </tr>
                        )
                      }) : (
                        <tr>
                          <td colSpan="5" className="text-center">No data</td>
                        </tr>
                      )}
                      </tbody>
                    </Table>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



        <Modal
          isOpen={this.state.modalToggle}
          scrollable
          centered
          size='xl'
          fullscreen="true |xxl-down'"
          toggle={this.printToggle}
        >
          <ModalHeader toggle={this.printToggle}>Bill</ModalHeader>
          <ModalBody>
            <style> {printBill()} </style>{' '}
            <div>
              {' '}
              <BillToPrint
                billData={this.state.printData}
                ref={(el) => (this.componentRef = el)}
              />{' '}
            </div>
          </ModalBody>
          <ModalFooter>
            <ReactToPrint
              trigger={() => {
                return <Button href='#/app/bill'> Print this out!</Button>;
              }}
              content={() => this.componentRef}
            />
          </ModalFooter>
        </Modal>

        {/* Print Invoice */}
        <Modal
          isOpen={this.state.modalInvToggle}
          scrollable
          centered
          size='xl'
          fullscreen="true |xxl-down'"
          toggle={this.printInvToggle}
        >
          <ModalHeader toggle={this.printInvToggle}>Invoice</ModalHeader>
          <ModalBody>
            <style> {printInvPage()} </style>{' '}
            <InvoiceToPrint
              invData={this.state.printInvData}
              ref={(el) => (this.componentRef = el)}
            />
          </ModalBody>
          <ModalFooter>
            <ReactToPrint
              trigger={() => {
                return <Button href='#/app/packet'> Print this out!</Button>;
              }}
              content={() => this.componentRef}
            />
          </ModalFooter>
        </Modal>

        {/* Print Packages */}
        <Modal
          isOpen={this.state.modalPrintPackagesToggle}
          scrollable
          centered
          size='xl'
          fullscreen="true |xxl-down'"
          toggle={this.printPackagesToggle}
        >
          <ModalHeader toggle={this.printPackagesToggle}>
            Shipping Mark
          </ModalHeader>
          <ModalBody>
            <style> {printPackages()} </style>{' '}
            <div className="print-wrapper">
              {' '}
              <PrintBillup
                // invData={this.state.printInvData}
                billData={this.state.printInvData}
                ref={(el) => (this.componentRef = el)}
              />{' '}
            </div>
          </ModalBody>
          <ModalFooter>
            <ReactToPrint
              trigger={() => {
                return (
                  <Button href='#/app/printPackages'> Print this out!</Button>
                );
              }}
              content={() => this.componentRef}
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalTogglePickup}
          toggle={this.toggleShowPickupModal}
          size="xl"
          fullscreen="true |xxl-down'"
          style={{ transform: "translateY(100px)" }}
        >
          <div className={s.contentBoxPickupModal}>
            <ModalHeader
              toggle={this.toggleShowPickupModal}
              style={{ paddingRight: '15px' }}
            >
            </ModalHeader>
            <div className={s.headerPickupModal}>
              <div
                className={`${s.headerItem} ${this.state.pickupStatus === 1 &&
                s.headerItemChecked}`}
                onClick={() => this.togglePickupStatus(1)}
              >
                Request a pickup
              </div>
              <div
                className={`${s.headerItem} ${this.state.pickupStatus === 2 &&
                s.headerItemChecked}`}
                onClick={() => this.togglePickupStatus(2)}
              >
                Pickup List
              </div>
            </div>
            <ModalBody style={{ padding: "20px" }}>
              {this.state.pickupStatus === 1 ? (
                <div className={s.bodyPickupModal}>
                  <form>
                    <Row>
                      <Col>
                        <textarea
                          rows="14"
                          style={{
                            minWidth: "100%",
                            border: "1px solid #d9d9d9",
                            borderRadius: "4px",
                            padding: "4px 11px",
                          }}
                          name="packageId"
                          onChange={this.onCreatePickupChange}
                          placeholder="Please input your order number here that need pickup service"
                        />
                      </Col>
                      <Col>
                        <Form>
                          <FormGroup row>
                            <Label for="pickupType" sm={4}>
                              Pickup Type
                            </Label>
                            <Col sm={8}>
                              <Input
                                id="pickupType"
                                name="type"
                                type="select"
                                onChange={this.onCreatePickupChange}
                                required
                              >
                                <option>Xe tải</option>
                                <option>Bán tải</option>
                                <option>Xe máy</option>
                              </Input>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="pickupDate" sm={4}>
                              Pickup Datetime
                            </Label>
                            <Col sm={8}>
                              <DatePicker
                                className={`form-control pickup-date ${s.pickupDateTimeModal}`}
                                id="pickupDate"
                                placeholderText="Pickup Datetime"
                                name="datetime"
                                dateFormat="dd/MM/yy"
                                selected={this.state.pickup.datetime}
                                onChange={this.handleDatePickupSelect}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="pickupAddress" sm={4}>
                              Pickup Address
                            </Label>
                            <Col sm={8}>
                              <Input id="pickupAddress" name="address"
                                     onChange={this.onCreatePickupChange} required/>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="pickupAddress" sm={4}>
                              Gross Weight
                            </Label>
                            <Col sm={8}>
                              <Input id="grossWeight" name="grossWeight" onChange={this.onCreatePickupChange} required/>
                            </Col>
                          </FormGroup>
                          <FormGroup row style={{ paddingBottom: "50px" }}>
                            <Label for="pickupRemark" sm={4}>
                              Remark
                            </Label>
                            <Col sm={8}>
                              <Input id="pickupRemark" name="remark" onChange={this.onCreatePickupChange}/>
                            </Col>
                          </FormGroup>
                          <Button
                            style={{
                              position: "absolute",
                              bottom: "0",
                              right: "20px",
                              minWidth: "155px",
                            }}
                            onClick={() =>this.submitPickup()}
                          >
                            Send pickup request
                          </Button>
                        </Form>
                      </Col>
                    </Row>
                  </form>
                </div>
              ) : (
                <>
                  <div>
                    {/*<DateRangePickerCustom
                      onStartDateChange={(e) => this.setStartDate(e)}
                      onEndDateChange={(e) => this.setEndDate(e)}
                      startDate={startDate}
                      endDate={endDate}
                      focus={focus}
                      onFocusChange={(e) => this.handleFocusChange(e)}
                    />*/}
                    <PickupListTable data={this.state.listPickup} deletePickup={this.deletePickup} updatePickup={this.updatePickup} key={this.state.pickupStatus} />
                  </div>
                </>
              )}
            </ModalBody>
          </div>
        </Modal>

        {/* Print SM */}
        <Modal
          isOpen={this.state.modalSMToggle}
          scrollable
          centered
          size='xl'
          fullscreen="true |xxl-down'"
          toggle={this.printSMToggle}
        >
          <ModalHeader toggle={this.printSMToggle}>
            Shipping Mark
          </ModalHeader>
          <ModalBody>
            <style></style>
            {' '}
            <div className="print-wrapper">
              {' '}
              <PrintSMOPS
                billData={this.state.printSMData}
                ref={(el) => (this.componentRef = el)}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <ReactToPrint
              trigger={() => {
                return (
                  <Button href='#/app/printPackages'> Print this out!</Button>
                );
              }}
              content={() => this.componentRef}
            />
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}
export default PackageDetail
