import React from 'react'
import { connect } from 'react-redux'
import {
  fetchPackage,
  removePackage,
  resetPackage,
  scanPackage,
} from '../../actions/barcode'
import axios from 'axios'
import fileDownload from 'js-file-download'
import { PACKAGE_ACTION } from '../../constants'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import QrCodeScanner from '../../components/QrCodeScanner';
import {
  Row,
  Col,
  Table,
  InputGroup,
  Input,
  Button,
  Card,
  CardHeader,
  CardBody,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap'
import Rounding from '../../utils/rounding'
import moment from "moment";
import _ from "lodash";
import BarcodeScanner from "../../components/BarcodeScanner";
let timer = null;
class Export extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      barcode: '',
      mawbCode: '',
      modalToggle: false,
      modalPrintPackagesToggle: false,
      printId: '',
      printData: '',
      modalInvToggle: false,
      disableDownloadButton: false,
      printInvData: '',
      BRANCH: ['HCM', 'HN', 'DAD'],
      selectedBranch: 'HCM',
      exportType: 'EXPORT',
      currentHawbCode: '',
      scanned: false,
      scannedPackage: [],

      // Quagga
      devices: [],
      scanning: false,
      status: "Stoped",
      results: [],
      deviceId: null,
      standards: [
        "code_128_reader",
        "ean_reader",
        "ean_8_reader",
        "code_39_reader",
        "code_39_vin_reader",
        "codabar_reader",
        "upc_reader",
        "upc_e_reader",
        "i2of5_reader",
        "2of5_reader",
        "code_93_reader"
      ],
      standardId: "code_128_reader"
    }

    this.printToggle = this.printToggle.bind(this)
    this.printPackagesToggle = this.printPackagesToggle.bind(this)
    this.onClickPrint = this.onClickPrint.bind(this)
    this.onClickPrintInv = this.onClickPrintInv.bind(this)
    this.onClickPrintPackages = this.onClickPrintPackages.bind(this)
    this.printInvToggle = this.printInvToggle.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.onSelectedBranch = this.onSelectedBranch.bind(this)
    this.onexportType = this.onexportType.bind(this)

    this._scan = this._scan.bind(this);
    this._standard = this._standard.bind(this);
  }

  handleInput = (e) => {
    const { name, value } = e.target
    const newInfo = this.state;
    newInfo[name] = value;
    this.setState(newInfo)
  }

  handleSuccess(result) {
    console.log(result)
    if (this.state.scanned) {
      return
    }
    setTimeout(() => {
      this.setState({scanned: false})
    }, 5000)
    this.setState({barcode: result.codeResult.code})
    this.getItem()
    this.setState({scanned: true})
  }

  handleError(error) {
    console.error(`Error: ${error}`);
  }

  async getItem() {
    const { barcode, mawbCode, selectedBranch, exportType } = this.state
    this.setState({scannedPackage: [...this.state.scannedPackage, barcode]})
    const b = await this.props.dispatch(scanPackage(barcode, PACKAGE_ACTION.EXPORT, mawbCode, selectedBranch, exportType))
    if (b) {
      // await this.openEditModal(b, barcode)
      await this.inputHawbTracking(barcode, PACKAGE_ACTION.EXPORT, mawbCode, selectedBranch)
    }
    this.setState({ barcode: '' })
    const { message } = this.props
    if (!!message) {
      alert(message)
      return;
    }
    document.getElementById("kgInput").focus();
  }

  async inputHawbTracking(code, action, mawbCode, branch) {
    try {
      if (code !== this.state.currentHawbCode) {
        await axios.post(`/create-hawbtracking`, {
          hawbCode: code,
          action: action,
          mawbCode: mawbCode,
          branch: branch
        })
        this.setState({ currentHawbCode: code })
      }
    } catch (e) {
      console.log(e)
    }
  }

  getHawbCode = (pkg) => {
    let hawbCode = ''
    if (pkg.exportJSON && pkg.hawbCode) {
      let exportJSON = JSON.parse(pkg.exportJSON)
      let hawbArr = pkg.hawbCode.trim().split(',')
      hawbArr = hawbArr.map((hawb) => hawb.trim())
      hawbArr.forEach((hawb, index) => {
        if (exportJSON[hawb]) {
          hawbCode += hawb + ' - ' + moment(exportJSON[hawb]).format('DD/MM/YYYY hh:mm') + ' \n '
        }
      })
    }
    return hawbCode
  }

  onClickPrint(e, pgk) {
    this.setState({ printData: pgk })
    this.printToggle()
  }

  printToggle() {
    this.setState((prevState) => ({ modalToggle: !prevState.modalToggle }))
  }

  printPackagesToggle() {
    this.setState((prevState) => ({
      modalPrintPackagesToggle: !prevState.modalPrintPackagesToggle,
    }))
  }

  onClickPrintInv(e, pgk) {
    this.setState({ printInvData: pgk })
    this.printInvToggle()
  }

  onClickPrintPackages(e, pgk) {
    this.setState({ printInvData: pgk })
    this.printPackagesToggle()
  }

  printInvToggle() {
    this.setState((prevState) => ({
      modalInvToggle: !prevState.modalInvToggle,
    }))
  }

  componentDidMount() {
    navigator.mediaDevices
      .enumerateDevices({ video: true, audio: true })
      .then((devices) => {
        const deviceList = devices
          .filter((device) => device.kind === "videoinput")
          .map((device) => {
            return { deviceId: device.deviceId, label: device.label };
          });

        this.setState({
          devices: deviceList
        });

        setTimeout(() => {
          this.setState({ deviceId: deviceList[0]?.deviceId, scanning: true, status: "Scanning" })
        }, 2000)
      });


    this.props.dispatch(resetPackage())
  }


  _scan = (event) => {
    const deviceId = event.target.value;
    if (deviceId && deviceId !== "test" && this.state.standardId) {
      this.setState({ deviceId, scanning: true, status: "Scanning" }, () =>
        setTimeout(3000)
      );
    }
  };

  _standard = (event) => {
    if (
      this.state.deviceId &&
      this.state.deviceId !== "test" &&
      this.state.standardId
    ) {
      const standardId = event.target.value;
      this.setState({ standardId, scanning: true });
    }
  };

  _onDetected = (result) => {
    this.setState({ results: [result] });
    this.handleSuccess(result);
  };
  async exportData() {
    await this.setState({ disableDownloadButton: true })
    const params = this.props.items.map((i) => i.kgCode)
    axios
      .get('/download', {
        params: { code: params },
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(
          res.data,
          `DOWNLOAD_DATA_${new Date().toJSON().slice(0, 10)}.xlsx`,
        )
        this.setState({ disableDownloadButton: false })
      })
      .catch(() => {
        this.setState({ disableDownloadButton: false })
      })
    return
  }

  removeItem(code) {
    this.props.dispatch(removePackage(code))
  }

  resetItems() {
    this.props.dispatch(resetPackage())
  }

  onSelectedBranch = (e) => {
    const value = e.target.value
    this.setState({selectedBranch: value});
  }
  onexportType = (e) => {
    const value = e.target.value
    console.log(value)
    this.setState({exportType: value});
  }

  getHawbCodeArr = (pkg) => {
    let hawbCode = []
    if (pkg.exportJSON && pkg.hawbCode) {
      let exportJSON = JSON.parse(pkg.exportJSON)
      let hawbArr = pkg.hawbCode.trim().split(',')
      hawbArr = hawbArr.map((hawb) => hawb.trim())
      hawbArr.forEach((hawb, index) => {
        if (exportJSON[hawb]) {
          if (this.state.scannedPackage.indexOf(hawb) !== -1) {
            hawbCode.push(hawb)
          }
        }
      })
    }
    return hawbCode
  }

  render() {
    const { barcode, mawbCode } = this.state
    let  { items } = this.props
    const arr = items
    items = _.values(_.groupBy(arr, 'id')).map(_.last);
    let totalPacks = 0
    items &&
      items.forEach((element) => {
        let packs = JSON.parse(element.packs) || []
        if (packs.length && packs[0].quantity !== 0) {
          totalPacks = totalPacks + JSON.parse(element.packs).length
        }
      })
    let totalWeight = parseFloat(0)
    items &&
      items.forEach((element) => {
        let packs = JSON.parse(element.packs) || []
        packs.forEach((packInfo) => {
          if (packInfo.weight) {
            totalWeight =
              totalWeight + Rounding(packInfo.weight * packInfo.quantity)
          }
        })
      })
    return (
      <div>
        <Breadcrumb>
          <BreadcrumbItem>TRANG</BreadcrumbItem>
          <BreadcrumbItem>Barcode</BreadcrumbItem>
          <BreadcrumbItem active>Xuất hàng</BreadcrumbItem>
        </Breadcrumb>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <Row>
          <Col sm={12} md={3} className="mb-3">
            <Card>
              <CardHeader tag="h5">Xuất hàng</CardHeader>
              <CardBody>
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    this.getItem()
                  }}
                >
                  <InputGroup>
                    <Input
                      id="kgInput"
                      autoFocus
                      name="barcode"
                      value={barcode}
                      onChange={this.handleInput}
                    />
                    <Button type="submit" className="ml-3">Enter</Button>
                  </InputGroup>
                </form>
                <label style={{marginTop: '20px'}}>Scan by Camera</label>
                <div>
                  <div>
                    <div className="header">
                      <div>
                        Camera:
                        <select onChange={this._scan}
                        value={this.state.deviceId}
                        >
                          <option value="test">Select camera</option>
                          {this.state.devices.map((device) => {
                            return (
                              <option value={device.deviceId} key={device.deviceId}>
                                {device.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {this.state.scanning && this.state.standardId ? (
                      <BarcodeScanner
                        deviceId={this.state.deviceId}
                        onDetected={this._onDetected}
                        standardId={this.state.standardId}
                      />
                    ) : null}

                    {this.state.results.map((result) => {
                      return (
                        <div>
                          <div>{result.codeResult.code}</div>
                          <div>{result.codeResult.format}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={3} className="mb-3">
            <Card>
              <CardHeader tag="h5">MAWB Code</CardHeader>
              <CardBody>
                <InputGroup>
                  <Input
                    id="mawbCode"
                    name="mawbCode"
                    value={mawbCode}
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={3} className="mb-3">
            <Card>
              <CardHeader tag="h5">BRANCH</CardHeader>
              <CardBody>
                <select value={this.state.selectedBranch}
                        style={{maxWidth: '100%', display: "block"}}
                        className="custom-select mr-sm-2"
                        onChange={(e) => this.onSelectedBranch(e)}
                >
                  {this.state.BRANCH && this.state.BRANCH.map((option) => (
                    <option value={option}>{option}</option>
                  ))}
                </select>
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={3} className="mb-3">
            <Card>
              <CardHeader tag="h5">TYPE</CardHeader>
              <CardBody>
                <select value={this.state.exportType}
                        style={{maxWidth: '100%', display: "block"}}
                        className="custom-select mr-sm-2"
                        onChange={(e) => this.onexportType(e)}
                >
                    <option value="EXPORT">EXPORT</option>
                    <option value="DOMESTIC">DOMESTIC</option>
                </select>
              </CardBody>
            </Card>
          </Col>
          <Col sm={12}>
          <div className="d-flex justify-content-between my-4 align-items-center">
            <h2 className='title-page'>Packages List</h2>
            {items.length > 0 && (
              <Button
              type="button" className="butt btn-w-170 text-uppercase"
                disabled={this.state.disableDownloadButton}
                onClick={() => this.exportData()}
              >
                <i className="mr-1 fa fa-download" aria-hidden="true"></i>
                Tải về Excel
              </Button>
            )}
          </div>
              <Table hover responsive size="" striped>
                <thead className="text-center">
                  <tr>
                    <th>#</th>
                    <th>TP Bill</th>
                    <th>Reference Code</th>
                    <th>HAWB Code</th>
                    <th>Contact</th>
                    <th>Address</th>
                    <th>Sent Date</th>
                    <th>Tracking</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {items.length?items.map((pkg, i) => (
                    this.getHawbCodeArr(pkg).length > 0 && this.getHawbCodeArr(pkg).map((hawb, index) => (
                    <tr>
                      <td className="align-middle">{i + 1}</td>
                      <td className="align-middle">{pkg.kgCode || ''}</td>
                      <td className="align-middle">
                        {pkg.referenceCode || ''}
                      </td>
                      <td className="align-middle">
                        {
                          hawb + ' - ' + moment(JSON.parse(pkg.exportJSON)[hawb]).format('DD/MM/YYYY hh:mm')
                        }
                      </td>
                      <td className="align-middle">
                        {' '}
                        {pkg.receiverCompanyName} <br />
                        {pkg.receiverName} <br />
                        {pkg.receiverEmail}
                      </td>
                      <td className="align-middle">
                        {' '}
                        {pkg.receiverCity +
                          ' / ' +
                          pkg.receiverCountry +
                          ' / ' +
                          pkg.receiverPostalCode}{' '}
                        <br /> {pkg.receiverAddr1} <br />{' '}
                        {pkg.receiverAddr2} <br /> {pkg.receiverAddr3}{' '}
                      </td>

                      <td className="align-middle">
                        {new Date(pkg.createdAt).toLocaleDateString()}{' '}
                      </td>

                      <td className="align-middle">
                        {pkg.logisticCode && (
                          <a
                            href={`https://tracking.gopost.vn?tpCode=${pkg.kgCode}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {pkg.logisticCode}
                          </a>
                        )}
                      </td>
                    </tr>
                      ))
                  )):
                  <tr>
                    <td colSpan="100" className='text-center'>
                      Danh sách trống. Vui lòng quéc mã barcode trên kiện hàng.
                    </td>
                  </tr>
                  }
                </tbody>
              </Table>
          </Col>
        </Row>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.barcode.isFetching,
    items: state.barcode.items,
    message: state.barcode.message,
  }
}

export default connect(mapStateToProps)(Export)
