import { confirmAlert } from 'react-confirm-alert';

export const CREATE_PACKAGE_INITIAL = 'CREATE_PACKAGE_INITIAL';
export const CREATE_PACKAGE_REQUEST = 'CREATE_PACKAGE_REQUEST';
export const CREATE_PACKAGE_SUCCESS = 'CREATE_PACKAGE_SUCCESS';
export const CREATE_PACKAGE_FAILURE = 'CREATE_PACKAGE_FAILURE';

export const FETCH_PACKAGES_REQUEST = 'FETCH_PACKAGES_REQUEST';
export const FETCH_PACKAGES_SUCCESS = 'FETCH_PACKAGES_SUCCESS';
export const FETCH_PACKAGES_FAILURE = 'FETCH_PACKAGES_FAILURE';

export const UPDATE_PACKAGE_REQUEST =  'UPDATE_PACKAGE_REQUEST';
export const UPDATE_PACKAGE_SUCCESS =  'UPDATE_PACKAGE_SUCCESS';
export const UPDATE_PACKAGE_FAILURE =  'UPDATE_PACKAGE_FAILURE';

export const DELETE_PACKAGE_REQUEST =  'DELETE_PACKAGE_REQUEST';
export const DELETE_PACKAGE_SUCCESS =  'DELETE_PACKAGE_SUCCESS';
export const DELETE_PACKAGE_FAILURE =  'DELETE_PACKAGE_FAILURE';

/*********   CREATE PACKAGE   *****/
function createPackageInitial() {
  return {
    type: CREATE_PACKAGE_INITIAL,
    isCreating: false,
  };
}

function requestCreatePackage(pack) {
  return {
    type: CREATE_PACKAGE_REQUEST,
    isCreating: true,
    package: pack,
  };
}

function createPackageSuccess(pack) {
  return {
    type: CREATE_PACKAGE_SUCCESS,
    isCreating: false,
    package: pack,
  };
}

function createPackageError(message) {
  return {
    type: CREATE_PACKAGE_FAILURE,
    isCreating: false,
    message,
  };
}

export function createPackage(packageData) {
  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `mutation {
                createPackage(
                    companyUserID: "${packageData.companyUserID}",
                    owner: "${packageData.owner}",
                    senderCompanyName: "${packageData.senderCompanyName}", 
                    senderName: "${packageData.senderName}",
                    senderPhone: "${packageData.senderPhone}",
                    senderEmail: "${packageData.senderEmail}",
                    
                    receiverCompanyName: "${packageData.receiverCompanyName}",
                    receiverName: "${packageData.receiverName}",
                    receiverPhone: "${packageData.receiverPhone}",
                    receiverEmail: "${packageData.receiverEmail}",
                    receiverCountry: "${packageData.receiverCountry}",
                    receiverPostalCode: "${packageData.receiverPostalCode}",
                    receiverCity: "${packageData.receiverCity}",
                    receiverProvince: "${packageData.receiverProvince}",
                    receiverAddr1: "${packageData.receiverAddr1}",
                    receiverAddr2: "${packageData.receiverAddr2}",
                    receiverAddr3: "${packageData.receiverAddr3}",

                    logisticService: "${packageData.logisticService}",
                    referenceCode: "${packageData.referenceCode}", 

                    packageType: "${packageData.packageType}",

                    docCount: ${packageData.docCount},
                    docWeight: ${packageData.docWeight},

                    packContent: "${packageData.packContent}",
                    packInvoiceValue: "${packageData.packInvoiceValue}",
                    packInvoiceUnit: "${packageData.packInvoiceUnit}",

                    packs: "${packageData.packs}",
                    invoiceExportFormat: "${packageData.invoiceExportFormat}",
                    invoices: "${packageData.invoices}",
                    kgCode: "${packageData.kgCode}",
                    hawbCode: "${packageData.hawbCode}",
                    reportStatus: "${packageData.reportStatus}",
                    totalPrice: "${packageData.totalPrice}",
                    ){
                      id,companyUserID,owner,hawbCode,senderCompanyName,senderName,senderPhone,senderEmail,
                      receiverCompanyName,receiverName,receiverPhone,receiverEmail,receiverCountry,receiverPostalCode,receiverCity,receiverProvince,receiverAddr1,receiverAddr2,receiverAddr3,
                      logisticService,logisticCode,referenceCode,kgCode,reportStatus,
                      packageType,
                      docCount,docWeight,
                      packContent,packInvoiceValue,packInvoiceUnit,
                      updatedAt,createdAt,packs,invoices,invoiceExportFormat,totalPrice}
              }`,
    }),
    credentials: 'include',
  };

  return dispatch => {
    // We dispatch requestCreatePackage to kickoff the call to the API
    dispatch(requestCreatePackage(packageData));
    return fetch('/graphql', config)
      .then(response => response.json().then(responseJson => ({
        responseJson })))
      .then(({ responseJson }) => {

        if (responseJson.errors) {
          dispatch(createPackageError(responseJson.errors[0].message));
          return Promise.reject(responseJson.errors);
        }
        // Dispatch the success action
        dispatch(createPackageSuccess(responseJson.data.createPackage));
        setTimeout(() => {
          dispatch(createPackageInitial());
        }, 5000);
        return Promise.resolve(responseJson.data.createPackage);
      })
      .catch(err => console.error('Error: ', err));
  };
}

/*********   FETCH PACKAGES   *****/
function requestFetchPackages() {
  return {
    type: FETCH_PACKAGES_REQUEST,
    isFetching: true,
  };
}

function fetchPackagesSuccess(packages) {
  return {
    type: FETCH_PACKAGES_SUCCESS,
    isFetching: false,
    packages,
  };
}

function fetchPackagesError(message) {
  return {
    type: FETCH_PACKAGES_FAILURE,
    isFetching: false,
    message,
  };
}

export function fetchPackages(params) {
  console.log('params ', params)
  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `{packages(
          companyUserID: "${params.companyUserID}",
          limit: ${params.limit}, 
          page: ${params.page}, 
          owner: "${params.owner}", 
          receiverCompanyName: "${params.receiverCompanyName}",
          senderCompanyName: "${params.senderCompanyName}",
          code: "${params.billCode}",
          receiverAddr: "${params.receiverAddr}",
          referenceCode: "${params.referenceCode}",
          logisticCode: "${params.logisticCode}",
          logisticService: "${params.logisticService}",
          fromDate: "${params.fromDate}",
          toDate: "${params.toDate}",
          ){
            count,
            rows{id,companyUserID,owner,senderCompanyName,senderName,senderPhone,senderEmail,
              receiverCompanyName,receiverName,receiverPhone,receiverEmail,receiverCountry,receiverPostalCode,receiverCity,receiverProvince,receiverAddr1,receiverAddr2,receiverAddr3,
              logisticService,logisticCode,referenceCode,kgCode,
              packageType,
              docCount,docWeight,
              packContent,packInvoiceValue,packInvoiceUnit,
              updatedAt,createdAt,status,statusType1,
              exportAt,importAt,
              packs,invoices,invoiceExportFormat,hawbCode,reportStatus,totalPrice,exportType} 
            }
          }`,
    }),
    credentials: 'include',
  };

  return dispatch => {
    dispatch(requestFetchPackages());
    return fetch('/graphql', config)
      .then(response =>
        response.json().then(responseJson => ({
          responseJson,
        })),
      )
      .then(({ responseJson }) => {
        if (responseJson.errors) {
          // If there was a problem, we want to
          // dispatch the error condition

          dispatch(fetchPackagesError(responseJson.errors[0].message));
          return Promise.reject(responseJson.errors);
        }
        // Dispatch the success action
        dispatch(fetchPackagesSuccess(responseJson.data.packages));
        return Promise.resolve(responseJson.data.packages);
      })
      .catch(err => console.error('Error: ', err));
  };
}

/*********   UPDATE PACKAGE   *****/
function requestUpdatePackage() {
  return {
    type: UPDATE_PACKAGE_REQUEST,
    isFetching: true,
  };
}

function updatePackageSuccess(pack) {
  return {
    type: UPDATE_PACKAGE_SUCCESS,
    isFetching: false,
    package: pack,
  };
}

function updatePackageError(message) {
  return {
    type: UPDATE_PACKAGE_FAILURE,
    isFetching: false,
    message,
  };
}

export function updatePackage(postData) {
  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `mutation{
        updatePackage(
          id: "${postData.id}",
          receiverCompanyName: "${postData.receiverCompanyName}",
          receiverName: "${postData.receiverName}",
          receiverPhone: "${postData.receiverPhone}",
          receiverCountry: "${postData.receiverCountry}",
          receiverCity: "${postData.receiverCity}",
          receiverProvince: "${postData.receiverProvince}",

          logisticCode: "${postData.logisticCode}",
          referenceCode: "${postData.referenceCode}",
          receiverAddr1: "${postData.receiverAddr1}",
          receiverAddr2: "${postData.receiverAddr2}",
          status: "${postData.status}",
          statusType1: "${postData.statusType1}"
          ){
        id,companyUserID,owner,senderCompanyName,senderName,senderPhone,senderEmail,
              receiverCompanyName,receiverName,receiverPhone,receiverEmail,receiverCountry,receiverPostalCode,receiverCity,receiverProvince,receiverAddr1,receiverAddr2,receiverAddr3,
              logisticService,logisticCode,referenceCode,kgCode,
              packageType,
              docCount,docWeight,
              packContent,packInvoiceValue,packInvoiceUnit,
              updatedAt,createdAt,status,statusType1,
              exportAt,importAt,
              packs,invoices,invoiceExportFormat,hawbCode,reportStatus,totalPrice} }`,
    }),
    credentials: 'include',
  };

  return dispatch => {
    dispatch(requestUpdatePackage());

    return fetch('/graphql', config)
      .then(response =>
        response.json().then(responseJson => ({
          responseJson,
        })),
      )
      .then(({ responseJson }) => {
        if (responseJson.errors) {
          dispatch(updatePackageError(responseJson.errors[0].message));
          return Promise.reject(responseJson.errors);
        }

        dispatch(updatePackageSuccess(responseJson.data.updatePackage));
        return Promise.resolve(responseJson.data.updatePackage);
      })
      .catch(err => console.error('Error: ', err));
  };
}

/**************************** Delete Package ****************************/
export function requestDeletePackage() {
  return {
    type: DELETE_PACKAGE_REQUEST,
    isCreating: true,
  };
}

export function deletePackageSuccess(id) {
  return {
    type: DELETE_PACKAGE_SUCCESS,
    isCreating: false,
    id,
  };
}

export function deletePackageError(message) {
  return {
    type: DELETE_PACKAGE_FAILURE,
    isCreating: false,
    message,
  };
}

export function deletePackage(id) {
  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `mutation{
        deletePackage(id: "${id}")
          {id}}`,
    }),
    credentials: 'include',
  };

  return dispatch => {
    dispatch(requestDeletePackage());

    return fetch('/graphql', config)
      .then(response =>
        response.json().then(responseJson => ({
          responseJson,
        })),
      )
      .then(({ responseJson }) => {
        if (responseJson.errors) {
          dispatch(deletePackageError(responseJson.errors[0]));
          return Promise.reject(responseJson.errors);
        }

        dispatch(deletePackageSuccess(responseJson.data.deletePackage));
        return Promise.resolve(responseJson.data.deletePackage);
      })
      .catch(err => {
        confirmAlert({
          title: 'Đã có lỗi xảy ra',
          message: err[0].message,
          buttons: [
            {
              label: 'Đóng',
              onClick: () => {}
            }
          ]
        });
      });
  };
}


/********** Fetch Package Without Pagination and dispatch *************/
export async function packagesWithNoPagination(params) {
  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `{packages(
          companyUserID: "${params.companyUserID}",
          limit: ${params.limit}, 
          page: ${params.page}, 
          receiverCompanyName: "${params.receiverCompanyName}",
          code: "${params.billCode}",
          receiverAddr: "${params.receiverAddr}",
          fromDate: "${params.fromDate}",
          toDate: "${params.toDate}",
          ){
            count,
            rows{id,companyUserID,senderCompanyName,senderName,senderPhone,senderEmail,
              receiverCompanyName,receiverName,receiverPhone,receiverEmail,receiverCountry,receiverPostalCode,receiverCity,receiverProvince,receiverAddr1,receiverAddr2,receiverAddr3,
              logisticService,logisticCode,referenceCode,kgCode,
              packageType,
              docCount,docWeight,
              packContent,packInvoiceValue,packInvoiceUnit,
              updatedAt,createdAt,
              packs,invoices,invoiceExportFormat} 
            }
          }`,
    }),
    credentials: 'include',
  };

  const resp = await fetch('/graphql', config)
  const respJSON =  await resp.json()

  if (respJSON.errors) {
    console.log(respJSON.errors)
    return []
  }

  return respJSON.data.packages.rows
}


export function fetchPackage(code) {
  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
        query: `{packageByCode(
            code: "${code}",
        ){id,companyUserID,senderCompanyName,senderName,senderPhone,senderEmail,
            receiverCompanyName,receiverName,receiverPhone,receiverEmail,receiverCountry,receiverPostalCode,receiverCity,receiverProvince,receiverAddr1,receiverAddr2,receiverAddr3,
            logisticService,logisticCode,referenceCode,kgCode,
            packageType,
            docCount,docWeight,
            packContent,packInvoiceValue,packInvoiceUnit,
            updatedAt,createdAt,hawbCode,reportStatus,totalPrice
            packs,invoices,invoiceExportFormat}
        }`,
    }),
    credentials: 'include',
  };
  return fetch('/graphql', config)
    .then(response =>
      response.json().then(responseJson => ({
          packageByCode: responseJson.data.packageByCode,
        responseJson,
      })),
    )
    .then(({ packageByCode, responseJson }) => {
      if (!responseJson.data.packageByCode) {
        return Promise.reject(packageByCode);
      }
      return Promise.resolve(packageByCode);
    })
    .catch(err => console.error('Error: ', err));
}




export function updatePackageByCode(packageData) {
  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `mutation {
        updatePackageByCode(
        companyUserID: "${packageData.companyUserID}",
        senderCompanyName: "${packageData.senderCompanyName}", 
        senderName: "${packageData.senderName}",
        senderPhone: "${packageData.senderPhone}",
        senderEmail: "${packageData.senderEmail}",
        
        receiverCompanyName: "${packageData.receiverCompanyName}",
        receiverName: "${packageData.receiverName}",
        receiverPhone: "${packageData.receiverPhone}",
        receiverEmail: "${packageData.receiverEmail}",
        receiverCountry: "${packageData.receiverCountry}",
        receiverPostalCode: "${packageData.receiverPostalCode}",
        receiverCity: "${packageData.receiverCity}",
        receiverProvince: "${packageData.receiverProvince}",
        receiverAddr1: "${packageData.receiverAddr1}",
        receiverAddr2: "${packageData.receiverAddr2}",
        receiverAddr3: "${packageData.receiverAddr3}",

        logisticService: "${packageData.logisticService}",
        referenceCode: "${packageData.referenceCode}", 

        packageType: "${packageData.packageType}",

        docCount: ${packageData.docCount},
        docWeight: ${packageData.docWeight},

        packContent: "${packageData.packContent}",
        packInvoiceValue: "${packageData.packInvoiceValue}",
        packInvoiceUnit: "${packageData.packInvoiceUnit}",

        packs: "${packageData.packs}",
        kgCode: "${packageData.kgCode}",
        invoiceExportFormat: "${packageData.invoiceExportFormat}",
        invoices: "${packageData.invoices}",
        hawbCode: "${packageData.hawbCode}",
        reportStatus: "${packageData.reportStatus}",
        totalPrice: "${packageData.totalPrice}",
        ){
          id,companyUserID,senderCompanyName,senderName,senderPhone,senderEmail,
          receiverCompanyName,receiverName,receiverPhone,hawbCode,receiverEmail,receiverCountry,receiverPostalCode,receiverCity,receiverProvince,receiverAddr1,receiverAddr2,receiverAddr3,
          logisticService,logisticCode,referenceCode,kgCode,
          packageType,
          docCount,docWeight,
          packContent,packInvoiceValue,packInvoiceUnit,
          updatedAt,createdAt,packs,invoices,invoiceExportFormat,reportStatus,totalPrice}
        }`,
    }),
    credentials: 'include',
  };

  return fetch('/graphql', config)
    .then(response => response.json().then(responseJson => ({
      responseJson })))
    .then(({ responseJson }) => {
      if (responseJson.errors) {
        return Promise.reject(responseJson.errors);
      }
      return Promise.resolve(responseJson.data.updatePackageByCode);
    })
    .catch(err =>   Promise.reject(err));
}
